import { ApiMasterformatProgress, AssociationIds } from "avvir";
import { AvvirThunk } from "../make_eventful_action";
import updateProgressReport from "./update_progress_report";
import { UpdateProjectMasterformatProgressStartedEvent } from "../../events/update_project_masterformat_progress_started";
import { UpdateProjectMasterformatProgressCompletedEvent } from "../../events/update_project_masterformat_progress_completed";
import { AvvirThunkEvents } from "type_aliases";
import loadProjectMasterformatProgress from "./load_project_masterformat_progress";
import checkRunningGatewayProcess from "../check_running_gateway_process";

type DispatchedEvents =
  | UpdateProjectMasterformatProgressStartedEvent
  | UpdateProjectMasterformatProgressCompletedEvent
  | AvvirThunkEvents<typeof loadProjectMasterformatProgress>
  | AvvirThunkEvents<typeof checkRunningGatewayProcess>

function updateAllProgress(associationIds: AssociationIds): AvvirThunk<Promise<ApiMasterformatProgress[]>, DispatchedEvents> {
  return (dispatch, getState) => {
    if (!!associationIds.projectId && !!associationIds.floorId) {
      const projectId = associationIds.projectId;
      const floorId = associationIds.floorId;
      const floorProgress = dispatch(updateProgressReport({ projectId, floorId }))
      const projectProgress = dispatch(updateProgressReport({ projectId }));
      return Promise.all([floorProgress, projectProgress])
    }
  }

}

export default updateAllProgress;