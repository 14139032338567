import _ from "underscore";

import { Level1Id, Level2Id, Level3Id, Level4Id, UniformatId, UniformatLevel, UniformatLevel1, UniformatLevel2, UniformatLevel3, UniformatLevel4 } from "uniformat";
import level1 from "../../../resources/uniformat/level_1.json";
import level2 from "../../../resources/uniformat/level_2.json";
import level3 from "../../../resources/uniformat/level_3.json";
import level4 from "../../../resources/uniformat/level_4.json";

const level1Ids = new Set(_.keys(level1)) as Set<Level1Id>;
const level2Ids = new Set(_.keys(level2)) as Set<Level2Id>;
const level3Ids = new Set(_.keys(level3)) as Set<Level3Id>;
const level4Ids = new Set(_.keys(level4)) as Set<Level4Id>;

export default class UniformatConverter {
  static getLevelId(uniformatId: string, level: UniformatLevel): Level1Id | Level2Id | Level3Id | Level4Id | null {
    if (UniformatConverter.isUniformatId(uniformatId)) {
      if (uniformatId === "Other (Unlabeled)" || uniformatId === "OTHER") {
        return "Other (Unlabeled)";
      } else {
        switch (level) {
          case 1: {
            return uniformatId[0] as Level1Id;
          }
          case 2: {
            return (uniformatId as string).substring(0, 3) as Level2Id;
          }
          case 3: {
            return (uniformatId as string).substring(0, 5) as Level3Id;
          }
          case 4: {
            return uniformatId;
          }
          default: {
            return null;
          }
        }
      }
    } else {
      return null;
    }
  };

  static toLevel1Label(uniformatId: UniformatId): string | null {
    return getNameOrNull(1, uniformatId, getLevel1Name);
  }

  static toLevel2Label(uniformatId: UniformatId): string | null {
    return getNameOrNull(2, uniformatId, getLevel2Name);
  }

  static toLevel3Label(uniformatId: UniformatId): string | null {
    return getNameOrNull(3, uniformatId, getLevel3Name);
  };

  static toLevel4Label = (uniformatId: UniformatId): string | null => {
    return getNameOrNull(4, uniformatId, getLevel4Name);
  };

  static isUniformatId = (categoryId: string): categoryId is UniformatId => {
    // noinspection OverlyComplexBooleanExpressionJS
    return UniformatConverter.isLevel1Id(categoryId)
           || UniformatConverter.isLevel2Id(categoryId)
           || UniformatConverter.isLevel3Id(categoryId)
           || UniformatConverter.isLevel4Id(categoryId);
  };

  static isLevel1Id = (categoryId: string): categoryId is Level1Id => {
    return level1Ids.has(categoryId as Level1Id) || categoryId === "OTHER";
  };

  static isLevel2Id = (categoryId: string): categoryId is Level2Id => {
    return level2Ids.has(categoryId as Level2Id);
  };

  static isLevel3Id = (categoryId: string): categoryId is Level3Id => {
    return level3Ids.has(categoryId as Level3Id);
  };

  static isLevel4Id = (categoryId: string): categoryId is Level4Id => {
    return level4Ids.has(categoryId as Level4Id);
  };

  static getUniformatLevel = (categoryId: string): UniformatLevel => {
    switch(categoryId.length){
      case 1:
        return 1;
      case 3:
        return 2;
      case 5:
        return 3;
      case 8:
        return 4;
    }
  }

  static toUniformatBreakdown(categoryId: UniformatId): { level1: string, level2?: string, level3?: string, level4?: string } | null {
    if (UniformatConverter.isUniformatId(categoryId)) {
      const level1 = UniformatConverter.toLevel1Label(categoryId);
      const level2 = level1 && UniformatConverter.toLevel2Label(categoryId);
      const level3 = level2 && UniformatConverter.toLevel3Label(categoryId);
      const level4 = level3 && UniformatConverter.toLevel4Label(categoryId);
      return {
        level1,
        ...level2 && { level2 },
        ...level3 && { level3 },
        ...level4 && { level4 }
      };
    } else {
      return null;
    }
  }

  static toUniformatBreakdownString = (uniformatId: UniformatId): string | null => {
    const breakdown = UniformatConverter.toUniformatBreakdown(uniformatId);
    if (breakdown) {
      return Object.values(breakdown).join(" > ");
    } else {
      return null;
    }
  };
}

const getLevel1Name = (level1Id: Level1Id): UniformatLevel1[Level1Id] | null => {
  if (level1Id === "Other (Unlabeled)" || level1Id === "OTHER") {
    return "Other (Unlabeled)";
  } else {
    return level1[level1Id] || null;
  }
};

const getLevel2Name = (level2Id: Level2Id): UniformatLevel2[Level2Id] | null => {
  return level2[level2Id] || null;
};

const getLevel3Name = (level3Id: Level3Id): UniformatLevel3[Level3Id] | null => {
  return level3[level3Id] || null;
};

const getLevel4Name = (level4Id: Level4Id): UniformatLevel4[Level4Id] | null => {
  return level4[level4Id] || null;
};

function getNameOrNull(level: 1, uniformatId: UniformatId, getName: typeof getLevel1Name): UniformatLevel1[Level1Id] | null
function getNameOrNull(level: 2, uniformatId: UniformatId, getName: typeof getLevel2Name): UniformatLevel2[Level2Id] | null
function getNameOrNull(level: 3, uniformatId: UniformatId, getName: typeof getLevel3Name): UniformatLevel3[Level3Id] | null
function getNameOrNull(level: 4, uniformatId: UniformatId, getName: typeof getLevel4Name): UniformatLevel4[Level4Id] | null
function getNameOrNull(level: UniformatLevel, uniformatId: UniformatId, getName: typeof getLevel1Name | typeof getLevel2Name | typeof getLevel3Name | typeof getLevel4Name): string | null {
  if (uniformatId) {
    // @ts-ignore
    const levelId = UniformatConverter.getLevelId(uniformatId, level);
    // @ts-ignore
    return getName(levelId);
  } else {
    return null;
  }
}
