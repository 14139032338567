import { Reducer } from "redux";
import { ApiIntegrationCredentialsType, ResponseError } from "avvir";

import NotificationLevel from "../../models/domain/enums/notification_level";
import responseStatusText from "../../../resources/response_statuses.json";
import ToastNotification from "../../models/domain/toast_notification";
import { API_FAILURE } from "../../events/notifications/failures/api_failure";
import { COULD_NOT_SIGN_IN } from "../../events/notifications/failures/could_not_sign_in";
import { CUSTOM_FAILURE_MESSAGE_DISPLAYED } from "../../events/notifications/failures/custom_failure_message_displayed";
import { DISPLAY_CUSTOM_SUCCESS_MESSAGE } from "../../events/notifications/display_custom_success_message";
import { FILE_UPLOAD_FAILED } from "../../events/uploaded/file_upload_failed";
import { FLOOR_FILE_UPLOADED } from "../../events/uploaded/floor_file_uploaded";
import { INTEGRATION_CREDENTIALS_SAVED } from "../../events/integration_credentials/integration_credentials_saved";
import { INVITATION_SENT } from "../../events/notifications/invitation_sent";
import { NotificationEvents } from "../../events/notifications/notification_types";
import { PIPELINE_STARTED } from "../../events/superadmin/pipelines/pipeline_started";
import { PROJECT_FILE_UPLOADED } from "../../events/uploaded/project_file_uploaded";
import { PUSH_TO_PROCORE_FAILED } from "../../events/notifications/failures/push_to_procore_failed";
import { PUSH_TO_PROCORE_SUCCEEDED } from "../../events/notifications/push_to_procore_succeeded";
import { SAVED_SUCCESSFULLY } from "../../events/notifications/saved_successfully";
import { SCAN_DATASET_FILE_UPLOADED } from "../../events/uploaded/scan_dataset_file_uploaded";
import { TEXT_COPIED } from "../../events/notifications/text_copied";
import { TOAST_NOTIFICATION_EXPIRED } from "../../events/notifications/toast_notification_expired";
import { TOAST_NOTIFICATION_EXPIRES_SOON } from "../../events/notifications/toast_notification_expires_soon";
import { WBS_UPDATE_FAILED } from "../../events/notifications/failures/wbs_update_failed";

const credentialTypeNames = {
  [ApiIntegrationCredentialsType.DRONE_DEPLOY]: "Drone Deploy",
  [ApiIntegrationCredentialsType.MULTIVISTA]: "Multivista",
  [ApiIntegrationCredentialsType.STRUCTION_SITE]: "StructionSite"
};

export type ToastNotificationStore = ToastNotification | null;

const notificationActive = (toastNotification: ToastNotificationStore): boolean => {
  if (toastNotification) {
    return toastNotification.expiry > new Date();
  }
  return false;
};

const reduceToastNotification: Reducer<ToastNotificationStore | null, NotificationEvents> = (toastNotification = null, event) => {
  if (notificationActive(toastNotification) && event.type !== (TOAST_NOTIFICATION_EXPIRED || TOAST_NOTIFICATION_EXPIRES_SOON)) {
    return toastNotification;
  }

  switch (event.type) {
    case PROJECT_FILE_UPLOADED:
    case FLOOR_FILE_UPLOADED:
    case SCAN_DATASET_FILE_UPLOADED: {
      return new ToastNotification(NotificationLevel.success, "File uploaded successfully.");
    }
    case INVITATION_SENT: {
      return new ToastNotification(NotificationLevel.success, `You have invited ${event.payload.count} team member(s) to this project`, null, false);
    }
    case TEXT_COPIED: {
      return new ToastNotification(NotificationLevel.success, "Copied to clipboard.");
    }
    case COULD_NOT_SIGN_IN: {
      return new ToastNotification(NotificationLevel.failure, "Sign in failed.");
    }
    case API_FAILURE: {
      if (event.payload.error instanceof ResponseError) {
        let message = event.payload.error.responseBody.message;

        if (message == null) {
          message = event.payload.error.message;
        }
        let shortDesc = responseStatusText[event.payload.error.status];
        if (!shortDesc) {
          shortDesc = "Request failed";
        }
        return new ToastNotification(NotificationLevel.failure, `${shortDesc}:\n${message}`);
      } else {
        return new ToastNotification(NotificationLevel.failure, "Request failed.");
      }
    }
    case SAVED_SUCCESSFULLY: {
      return new ToastNotification(NotificationLevel.success, "Saved successfully.", false);
    }
    case INTEGRATION_CREDENTIALS_SAVED: {
      const credentials = event.payload?.integrationCredentials;
      if (credentials) {
        return new ToastNotification(NotificationLevel.success, `Username/password saved and authenticated with ${credentialTypeNames[credentials.credentialsType]}`);
      } else {
        return new ToastNotification(NotificationLevel.failure, "Unable to save username/password");
      }
    }
    //deprecated??
    case PIPELINE_STARTED: {
      return new ToastNotification(NotificationLevel.success, "Pipeline started.");
    }
    case PUSH_TO_PROCORE_SUCCEEDED: {
      const url = `${process.env.REACT_APP_PROCORE_URL}/${event.payload.procoreProjectId}/project/documents?folder_id=${event.payload.procoreFileId}`;
      return new ToastNotification(NotificationLevel.success, "Pushed to Procore: ", url, true);
    }
    case PUSH_TO_PROCORE_FAILED: {
      if (event.payload.error) {
        return new ToastNotification(NotificationLevel.failure, `Failed to push to Procore: ${event.payload.error.message}`);
      } else {
        return new ToastNotification(NotificationLevel.failure, "Failed to push to Procore.");
      }
    }
    case TOAST_NOTIFICATION_EXPIRES_SOON: {
      return { ...toastNotification, expiresSoon: true };
    }
    case TOAST_NOTIFICATION_EXPIRED: {
      return null;
    }

    case WBS_UPDATE_FAILED: {
      return new ToastNotification(NotificationLevel.failure, "Failed to update Work Breakdown Structure");
    }
    case FILE_UPLOAD_FAILED: {
      return new ToastNotification(NotificationLevel.failure, `${event.payload.fileTitle} upload failed. Error: ${event.payload.error.message}`);
    }
    case DISPLAY_CUSTOM_SUCCESS_MESSAGE: {
      return new ToastNotification(NotificationLevel.success, event.payload.message, event.payload.link, event.payload.manualClose, event.payload.panel);
    }
    case CUSTOM_FAILURE_MESSAGE_DISPLAYED: {
      return new ToastNotification(NotificationLevel.failure, event.payload.message, event.payload.link, event.payload.manualClose, event.payload.panel);
    }
    default: {
      return toastNotification;
    }
  }
};

export default reduceToastNotification;
