import Avvir, { ApiPipeline } from "avvir";

import DateConverter from "../../services/converters/date_converter";
import downloadExportedIfc from "./download_exported_ifc";
import exportIfcProcessChecked, { ExportIfcProcessCheckedEvent } from "../../events/export_ifc_process_checked";
import { AvvirThunk } from "../make_eventful_action";
import RunningProcessStatus from "../../models/domain/enums/running_process_status";
import { withPromise } from "../../services/utilities/general";

const checkExportedIfc = (associationIds, pipelineId, ifcType): AvvirThunk<Promise<void>, ExportIfcProcessCheckedEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;
    const checkAgainIfPending = (pipeline: ApiPipeline) => {
      const [succeeded, failure, checking] = withPromise<void>();
      if (pipeline.status === RunningProcessStatus.RUNNING) {
        dispatch(exportIfcProcessChecked(associationIds, ifcType, pipeline.externalId, "running", DateConverter.instantToDate(pipeline.startTime)));
        setTimeout(() => {
          Avvir.api.pipelines.checkPipelineStatus(associationIds, pipelineId, user)
            .then(checkAgainIfPending).then(succeeded).catch(failure);
        }, 5000);
      } else {
        dispatch(exportIfcProcessChecked(associationIds, ifcType, pipeline.externalId, "complete", DateConverter.instantToDate(pipeline.startTime)));
        // @ts-ignore
        dispatch(downloadExportedIfc(associationIds, ifcType));
        succeeded();
      }
      return checking;
    };
    return Avvir.api.pipelines.checkPipelineStatus(associationIds, pipelineId, user)
      .then(checkAgainIfPending);
  };
};

export default checkExportedIfc;
