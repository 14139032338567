import _ from "underscore";

import level1 from "../../../../resources/uniformat/level_1.json";
import level2 from "../../../../resources/uniformat/level_2.json";
import level3 from "../../../../resources/uniformat/level_3.json";
import level4 from "../../../../resources/uniformat/level_4.json";

import UniformatConverter from "../../converters/uniformat_converter";
import { Level1Id, Level2Id, Level3Id, Level4Id, UniformatId } from "uniformat";
import { DEVIATIONS_FROM_ALL_SCANS_SHOWN, DeviationsFromAllScansShownEvent, ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN, OnlyDeviationsFromCurrentScanShownEvent } from "../../../events/viewer/deviations_current_scan_filter_toggled";
import { TOLERANCE_FILTER_CHANGED, ToleranceFilterChangedEvent } from "../../../events/viewer/tolerance_filter_changed";
import { TRADE_HIDDEN, TradeHiddenEvent } from "../../../events/selection/trades/trade_hidden";
import { TRADE_SHOWN, TradeShownEvent } from "../../../events/selection/trades/trade_shown";
import { TRADE_SHOWN_ONLY, TradeShownOnlyEvent } from "../../../events/selection/trades/trade_shown_only";
import { NOT_BUILT_ELEMENTS_SHOWN, NotBuiltElementsShownEvent } from "../../../events/viewer/status_filter_events/not_built_elements_shown";
import { IN_PLACE_ELEMENTS_SHOWN, InPlaceElementsShownEvent } from "../../../events/viewer/status_filter_events/in_place_elements_shown";
import { DETECTED_DEVIATIONS_SHOWN, DetectedDeviationsShownEvent } from "../../../events/viewer/status_filter_events/detected_deviations_shown";
import { INCLUDED_ELEMENTS_SHOWN, IncludedElementsShownEvent } from "../../../events/viewer/status_filter_events/included_elements_shown";
import { NOT_BUILT_ELEMENTS_HIDDEN, NotBuiltElementsHiddenEvent } from "../../../events/viewer/status_filter_events/not_built_elements_hidden";
import { IN_PLACE_ELEMENTS_HIDDEN, InPlaceElementsHiddenEvent } from "../../../events/viewer/status_filter_events/in_place_elements_hidden";
import { DETECTED_DEVIATIONS_HIDDEN, DetectedDeviationsHiddenEvent } from "../../../events/viewer/status_filter_events/detected_deviations_hidden";
import { INCLUDED_ELEMENTS_HIDDEN, IncludedElementsHiddenEvent } from "../../../events/viewer/status_filter_events/included_elements_hidden";
import { VERIFIED_ELEMENTS_SHOWN, VerifiedElementsShownEvent } from "../../../events/viewer/status_filter_events/verified_elements_shown";
import { VERIFIED_ELEMENTS_HIDDEN, VerifiedElementsHiddenEvent } from "../../../events/viewer/status_filter_events/verified_elements_hidden";
import { DETECTED_OBSTRUCTING_ELEMENTS_SHOWN, DetectedObstructingElementsShownEvent } from "../../../events/viewer/status_filter_events/detected_obstructing_elements_shown";
import { DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN, DetectedObstructingElementsHiddenEvent } from "../../../events/viewer/status_filter_events/detected_obstructing_elements_hidden";
import { EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN, ExcludeFromAnalysisElementsHiddenEvent } from "../../../events/viewer/status_filter_events/exclude_from_analysis_elements_hidden";
import { EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN, ExcludeFromAnalysisElementsShownEvent } from "../../../events/viewer/status_filter_events/exclude_from_analysis_elements_shown";
import { Level1Id as MasterformatLevel1, Level2Id as MasterformatLevel2, Level3Id as MasterformatLevel3, Level4Id as MasterformatLevel4, Level5Id as MasterformatLevel5, MasterformatId } from "masterformat";
import { ISSUE_STATUS_FILTER_CHANGED, IssueStatusFilterChangedEvent } from "../../../events/viewer/issue_status_filter_changed";
import { MASTERFORMAT_FILTER_UPDATED, MasterformatFilterUpdatedEvent } from "../../../events/selection/work_breakdown_structure/masterformat_filter_updated";
import { VIEW_LOADED, ViewLoadedEvent } from "../../../events/loaded/view_loaded";
import { TRADE_SHOWN_ALL, TradeShownAllEvent } from "../../../events/selection/trades/trade_shown_all";
import { TRADE_HIDDEN_ALL, TradeHiddenAllEvent } from "../../../events/selection/trades/trade_hidden_all";
import { ANNOTATION_TITLE_FILTER_CHANGED, AnnotationTitleFilterChangedEvent } from "../../../events/viewer/annotation_title_filter_changed";
import { SHOW_UNCLASSIFIED_ELEMENTS_UPDATED, ShowUnclassifiedElementsUpdatedEvent } from "../../../events/show_unclassified_elements_updated";
import { MASTERFOMAT_FILTER_LOADED, MasterformatFilterLoadedEvent } from "../../../events/loaded/masterformat_filter_loaded";
import { INSPECTION_MODE_SELECTED, InspectionModeSelectedEvent } from "../../../events/selection/inspection_mode_selected";
import { InspectionMode } from "../../../models/domain/enums/inspection_modes";
import { REVIEW_MODE_TOGGLED_OFF, ReviewModeToggledOffEvent } from "../../../events/viewer/review_mode_toggled_off";
import { INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN, IncludeInAnalysisElementsHiddenEvent } from "../../../events/viewer/status_filter_events/include_in_analysis_elements_hidden";
import { INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN, IncludeInAnalysisElementsShownEvent } from "../../../events/viewer/status_filter_events/include_in_analysis_elements_shown";
import reduceTradesFilter, { TradesFilterEvents, TradesFilterStore } from "./reduce_trades_filter";

import type { Reducer } from "redux";
import type { Selected } from "type_aliases";


export type Level1Filter = { [code in Level1Id]: Selected } | Record<string, never>;
export type Level2Filter = { [code in Level2Id]: Selected } | Record<string, never>;
export type Level3Filter = { [code in Level3Id]: Selected } | Record<string, never>;
export type Level4Filter = { [code in Level4Id]: Selected } | Record<string, never>;
export type Level5Filter = { [code: string]: Selected } | Record<string, never>;
type Filters = Level1Filter | Level2Filter | Level3Filter | Level4Filter | Level5Filter;

export type MasterFormatClassLevel1Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel2Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel3Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel4Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatClassLevel5Filter = { [code: string]: Selected } | Record<string, never>;
export type MasterFormatUnclassifiedFilter = { selected: 1 | 0 };

type LevelNFilter<N extends number, T extends FilterType> = [
  never,
  T extends "masterformat" ? MasterFormatClassLevel1Filter : Level1Filter,
  T extends "masterformat" ? MasterFormatClassLevel2Filter : Level2Filter,
  T extends "masterformat" ? MasterFormatClassLevel3Filter : Level3Filter,
  T extends "masterformat" ? MasterFormatClassLevel4Filter : Level4Filter,
  T extends "masterformat" ? MasterFormatClassLevel5Filter : Level5Filter,
][N]

type LevelNCode<N extends number, T extends FilterType> = [
  never,
  T extends "masterformat" ? MasterformatLevel1 : Level1Id,
  T extends "masterformat" ? MasterformatLevel2 : Level2Id,
  T extends "masterformat" ? MasterformatLevel3 : Level3Id,
  T extends "masterformat" ? MasterformatLevel4 : Level4Id,
  T extends "masterformat" ? MasterformatLevel5 : Level5Filter,
][N];

export type FilterType = "masterformat" | "uniformat";
type FormatId<T extends FilterType> = T extends "masterformat" ? MasterformatId : UniformatId;

export type FormatCodeTreeNode = {
  visited: boolean,
  value: Selected,
  children: FormatCodeTreeNode[],
  code: FormatId<any>
};

export type MasterformatFilter = {
  level1: MasterFormatClassLevel1Filter,
  level2: MasterFormatClassLevel2Filter,
  level3: MasterFormatClassLevel3Filter,
  level4: MasterFormatClassLevel4Filter,
  level5?: MasterFormatClassLevel5Filter,
  unclassified?: MasterFormatUnclassifiedFilter
}

type UniformatFilter = {
  level1: Level1Filter
  level2: Level2Filter
  level3: Level3Filter
  level4: Level4Filter
}

type FormatFilter<T extends FilterType> = T extends "masterformat" ? MasterformatFilter : UniformatFilter;

export enum IssueStatusFilter {
  NONE = "NONE",
  UNEXPORTED = "UNEXPORTED",
  EXPORTED = "EXPORTED",
  EXPORTED_BIMTRACK_ONLY = "EXPORTED_BIMTRACK_ONLY",
  EXPORTED_BCF_ONLY = "EXPORTED_BCF_ONLY"
}

export interface FilterStore {
  toleranceFilter: number;
  issueStatusFilter: IssueStatusFilter;
  level1: Level1Filter;
  level2: Level2Filter;
  level3: Level3Filter;
  level4: Level4Filter;
  onlyShowDeviationsFromCurrentScan: boolean;
  notBuiltElementsShown: boolean;
  inPlaceElementsShown: boolean;
  detectedDeviationsShown: boolean;
  detectedObstructingElementsShown: boolean;
  includedElementsShown: boolean;
  verifiedElementsShown: boolean;
  excludeFromAnalysisElementsShown: boolean;
  includeInAnalysisElementsShown: boolean;
  masterFormats: MasterformatFilter;
  trades: TradesFilterStore;
  annotationTitleFilter: string;
  showUnclassifiedElements: boolean;
}

export const defaultFilter: FilterStore = {
  level1: _.mapObject(level1, () => 1) as Level1Filter,
  level2: _.mapObject(level2, () => 1) as Level2Filter,
  level3: _.mapObject(level3, () => 1) as Level3Filter,
  level4: _.mapObject(level4, () => 1) as Level4Filter,
  toleranceFilter: 0.0762,
  issueStatusFilter: IssueStatusFilter.NONE,
  onlyShowDeviationsFromCurrentScan: false, // this does not affect anything, see store_in_local_storage.ts
  notBuiltElementsShown: false,
  inPlaceElementsShown: true,
  detectedDeviationsShown: true,
  detectedObstructingElementsShown: true,
  includedElementsShown: true,
  verifiedElementsShown: true,
  excludeFromAnalysisElementsShown: false,
  includeInAnalysisElementsShown: true,
  annotationTitleFilter: "",
  masterFormats: {
    level1: {},
    level2: {},
    level3: {},
    level4: {},
    level5: {},
  },
  trades: {
    unclassified: {
      id: "unclassified",
      selected: 1,
      parentId: null,
      childIds: []
    }
  },
  showUnclassifiedElements: true,
};

type FilterEvents =
  | IssueStatusFilterChangedEvent
  | DetectedObstructingElementsHiddenEvent
  | DetectedObstructingElementsShownEvent
  | DetectedDeviationsHiddenEvent
  | DetectedDeviationsShownEvent
  | DeviationsFromAllScansShownEvent
  | IncludedElementsHiddenEvent
  | IncludedElementsShownEvent
  | VerifiedElementsShownEvent
  | VerifiedElementsHiddenEvent
  | InPlaceElementsHiddenEvent
  | InPlaceElementsShownEvent
  | MasterformatFilterUpdatedEvent
  | NotBuiltElementsHiddenEvent
  | NotBuiltElementsShownEvent
  | ExcludeFromAnalysisElementsHiddenEvent
  | ExcludeFromAnalysisElementsShownEvent
  | IncludeInAnalysisElementsHiddenEvent
  | IncludeInAnalysisElementsShownEvent
  | OnlyDeviationsFromCurrentScanShownEvent
  | ToleranceFilterChangedEvent
  | TradeHiddenAllEvent
  | TradeHiddenEvent
  | TradeShownAllEvent
  | TradeShownEvent
  | TradeShownOnlyEvent
  | ViewLoadedEvent
  | AnnotationTitleFilterChangedEvent
  | ShowUnclassifiedElementsUpdatedEvent
  | MasterformatFilterLoadedEvent
  | InspectionModeSelectedEvent
  | ReviewModeToggledOffEvent;

const reduceFilter: Reducer<FilterStore, FilterEvents> = (filter = defaultFilter, event?) => {
  const trades = reduceTradesFilter(filter.trades, event as TradesFilterEvents);
  if (trades !== filter.trades) {
    filter = {
      ...filter,
      trades
    };
  }
  switch (event?.type) {
    case TRADE_HIDDEN: {
      return toggleUniformatTrade(event.payload.code as UniformatId, 0, filter);
    }
    case TRADE_SHOWN: {
      return toggleUniformatTrade(event.payload.code as UniformatId, 1, filter);
    }
    case TRADE_HIDDEN_ALL: {
      return toggleAllTrades(filter, 0);
    }
    case TRADE_SHOWN_ALL: {
      return toggleAllTrades(filter, 1);
    }
    case VIEW_LOADED: {
      return {
        ...toggleTrades(event.payload.viewAttributes.filters.trades.shownUniformatCodes, filter),
        toleranceFilter: event.payload.viewAttributes.filters.deviationTolerance.value
      };
    }
    case TRADE_SHOWN_ONLY: { // unused currently (future story)
      return toggleOtherTrades(event.payload.code as UniformatId, filter);
    }
    case MASTERFORMAT_FILTER_UPDATED: {
      return {
        ...filter,
        masterFormats: {
          ...event.payload.masterformatFilter
        }
      };
    }
    case TOLERANCE_FILTER_CHANGED: {
      return {
        ...filter,
        toleranceFilter: event.payload
      };
    }
    case ISSUE_STATUS_FILTER_CHANGED: {
      return {
        ...filter,
        issueStatusFilter: event.payload
      };
    }
    case ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN: {
      return {
        ...filter,
        onlyShowDeviationsFromCurrentScan: true
      };
    }
    case DEVIATIONS_FROM_ALL_SCANS_SHOWN: {
      return {
        ...filter,
        onlyShowDeviationsFromCurrentScan: false
      };
    }
    case NOT_BUILT_ELEMENTS_SHOWN: {
      return {
        ...filter,
        notBuiltElementsShown: true
      };
    }
    case IN_PLACE_ELEMENTS_SHOWN: {
      return {
        ...filter,
        inPlaceElementsShown: true
      };
    }
    case DETECTED_DEVIATIONS_SHOWN: {
      return {
        ...filter,
        detectedDeviationsShown: true
      };
    }
    case DETECTED_OBSTRUCTING_ELEMENTS_SHOWN: {
      return {
        ...filter,
        detectedObstructingElementsShown: true
      };
    }
    case INCLUDED_ELEMENTS_SHOWN: {
      return {
        ...filter,
        includedElementsShown: true
      };
    }
    case REVIEW_MODE_TOGGLED_OFF: {
      return {
        ...filter,
        verifiedElementsShown: true
      };
    }
    case VERIFIED_ELEMENTS_SHOWN: {
      return {
        ...filter,
        verifiedElementsShown: true
      };
    }
    case NOT_BUILT_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        notBuiltElementsShown: false
      };
    }
    case IN_PLACE_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        inPlaceElementsShown: false
      };
    }
    case DETECTED_DEVIATIONS_HIDDEN: {
      return {
        ...filter,
        detectedDeviationsShown: false
      };
    }
    case DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        detectedObstructingElementsShown: false,
      };
    }
    case INCLUDED_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        includedElementsShown: false
      };
    }
    case VERIFIED_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        verifiedElementsShown: false
      };
    }
    case EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN: {
      return {
        ...filter,
        excludeFromAnalysisElementsShown: true,
      };
    }
    case EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        excludeFromAnalysisElementsShown: false,
      };
    }
    case INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN: {
      return {
        ...filter,
        includeInAnalysisElementsShown: true,
      };
    }
    case INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN: {
      return {
        ...filter,
        includeInAnalysisElementsShown: false,
      };
    }
    case ANNOTATION_TITLE_FILTER_CHANGED: {
      return {
        ...filter,
        annotationTitleFilter: event.payload
      };
    }
    case SHOW_UNCLASSIFIED_ELEMENTS_UPDATED: {
      return {
        ...filter,
        showUnclassifiedElements: event.payload
      };
    }
    case MASTERFOMAT_FILTER_LOADED: {
      return {
        ...filter,
        masterFormats: {
          ...event.payload
        }
      };
    }
    case INSPECTION_MODE_SELECTED: {
      const onlyShowDeviationsFromCurrentScan = event.payload === InspectionMode.inspect;
      return {
        ...filter,
        onlyShowDeviationsFromCurrentScan,
      };
    }
    default: {
      return filter;
    }
  }
};

export default reduceFilter;


const toggleUniFormat = (code: UniformatId, value: Selected, filter: FilterStore): UniformatFilter => {
  const uniformatLevel = UniformatConverter.getUniformatLevel(code);
  let level1: Level1Filter, level2: Level2Filter, level3: Level3Filter, level4: Level4Filter;
  if (uniformatLevel === 4) {
    level1 = { ...filter.level1 };
    level2 = { ...filter.level2 };
    level3 = { ...filter.level3 };
    level4 = { ...filter.level4 };
    level4[code] = value;
  } else {
    level1 = toggleChildTrades(code as Level1Id, value, filter.level1);
    level2 = toggleChildTrades(code as Level2Id, value, filter.level2);
    level3 = toggleChildTrades(code as Level3Id, value, filter.level3);
    level4 = toggleChildTrades(code as Level4Id, value, filter.level4);
  }
  return { level1, level2, level3, level4 };
};


export function toggleUniformatTrade(code: UniformatId, value: Selected, filter: FilterStore): FilterStore {
  let level1, level2, level3, level4, tempFilter, formatTree: Partial<FormatCodeTreeNode>[];
  tempFilter = toggleUniFormat(code as UniformatId, value, filter);
  //@ts-ignore
  formatTree = createUniFormatTree(tempFilter as UniformatFilter);
  formatTree.forEach(node => {
    toggleParents(node as FormatCodeTreeNode, "uniformat");
  });

  //@ts-ignore
  level1 = _.object(_.map(formatTree, tree => [tree.code, tree.value]));
  const level2Tree = _.flatten(_.map(formatTree, tree => tree.children));
  level2 = _.object(_.map(level2Tree, tree => [tree.code, tree.value]));
  const level3Tree = _.flatten(_.map(level2Tree, tree => tree.children));
  level3 = _.object(_.map(level3Tree, tree => [tree.code, tree.value]));
  const level4Tree = _.flatten(_.map(level3Tree, tree => tree.children));
  level4 = _.object(_.map(level4Tree, tree => [tree.code, tree.value]));
  return { ...filter, level1, level2, level3, level4 };
}

const toggleAllTrades = (filter: FilterStore, value: Selected): FilterStore => {
  return _.reduce(level1, (filterSoFar, level1Description, level1Id: UniformatId) => {
    return toggleUniformatTrade(level1Id, value, filterSoFar);
  }, filter);
};

const toggleTrades = (trades: UniformatId[], filter: FilterStore): FilterStore => {
  if (trades.length === 0) {
    return {
      ...filter,
      level1: defaultFilter.level1,
      level2: defaultFilter.level2,
      level3: defaultFilter.level3,
      level4: defaultFilter.level4
    };
  }
  const returnFilter = {
    ...filter,
    level1: _.mapObject(level1, () => 0) as Level1Filter,
    level2: _.mapObject(level2, () => 0) as Level2Filter,
    level3: _.mapObject(level3, () => 0) as Level3Filter,
    level4: _.mapObject(level4, () => 0) as Level4Filter
  };

  return trades.reduce((filterSoFar, trade) => toggleUniformatTrade(trade, 1, filterSoFar), returnFilter);
};

function createUniFormatTree<T extends FilterType>(filter: FormatFilter<T>) {
  return _(filter.level1).map((level1value: Selected, level1code: LevelNCode<1, T>) => {
    return {
      visited: false,
      value: level1value,
      code: level1code,
      children: _.map<LevelNFilter<2, T>>(
        // @ts-ignore
        _.pick<Selected, LevelNFilter<2, T>>(filter.level2, (level2value: Selected, level2code: LevelNCode<2, T>) => {
          return (level2code as string).startsWith(level1code);
        }),
        (level2value, level2code: LevelNCode<2, T>) => {
          return {
            visited: false,
            value: level2value,
            code: level2code,
            // @ts-ignore
            children: _.map(_.pick<Selected, LevelNFilter<3, T>>(filter.level3, (level3value, level3code: LevelNCode<3, T>) => {
              return (level3code as string).startsWith(level2code);
            }), (level3value, level3code: LevelNCode<3, T>) => {
              return {
                visited: false,
                value: level3value,
                code: level3code,
                // @ts-ignore
                children: _.map(_.pick<Selected, LevelNFilter<4, T>>(filter.level4, (level4value, level4code: LevelNCode<4, T>) => {
                  return (level4code as string).startsWith(level3code);
                }), (level4value, level4code: LevelNCode<4, T>) => {
                  return {
                    visited: false,
                    value: level4value,
                    code: level4code,
                    children: []
                  };
                })
              };
            })
          };
        })
    };
  });
}

export const toggleParents = (node: FormatCodeTreeNode, type?: FilterType) => {
  if (node.children.length === 0) {
    node.visited = true;
  } else if (node.visited === false) {
    if (canComputeValue(node)) {
      node.value = type === "masterformat" ? computeMasterformatValue(node) : computeValue(node);
      node.visited = true;
    } else {
      node.children.forEach(child => toggleParents(child, type));
    }
    node.value = type === "masterformat" ? computeMasterformatValue(node) : computeValue(node);
    node.visited = true;
  }
};

const canComputeValue = (treeNode: FormatCodeTreeNode): boolean => {
  return _.every(treeNode.children, (node) => node.visited === true);
};

const computeValue = (treeNode: FormatCodeTreeNode): Selected => {
  if (_.every(treeNode.children, (node => node.value === 1))) {
    return 1;
  } else if (_.every(treeNode.children, (node => node.value === 0))) {
    return 0;
  } else {
    return -1;
  }
};

const computeMasterformatValue = (treeNode: FormatCodeTreeNode): Selected => {
  if (_.every(treeNode.children, (node => node.value === 1))) {
    return 1;
  } else if (_.every(treeNode.children, (node => node.value === 0))) {
    return 0;
  } else {
    return -1;
  }
};

const toggleOtherTrades = (code: UniformatId, filter: FilterStore): FilterStore => {
  let filterResult = filter;
  _(filter.level1).keys().forEach((codeToDeselect: Level1Id) => {
    if (codeToDeselect !== code) {
      filterResult = toggleUniformatTrade(codeToDeselect, 0, filterResult);
    }
  });
  if (filter.level1[code] === 0) {
    filterResult = toggleUniformatTrade(code, 1, filterResult);
  }

  return filterResult;
};

export function toggleChildTrades<Trade extends Filters | MasterformatFilter>(code: UniformatId | MasterformatId, newValue: Selected, trades: Trade): Trade {
  return _.mapObject(trades, (originalValue, childCode: UniformatId | MasterformatId) => {
    if ((childCode as string).startsWith(code)) {
      return newValue;
    } else {
      return originalValue;
    }
  }) as Trade;
}
