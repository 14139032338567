import { CustomBimColors } from "../../services/utilities/bim_colors";
import { AvvirEvent } from "type_aliases";

export type BimColorsSavedEvent = AvvirEvent<typeof BIM_COLORS_SAVED, CustomBimColors>

export function bimColorsSaved(colors: CustomBimColors): BimColorsSavedEvent {
  return {
    type: BIM_COLORS_SAVED,
    payload: colors
  };
}

export const BIM_COLORS_SAVED = "bim_colors_saved";
