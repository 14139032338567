import { Reducer } from "redux";

import { toggleAllElements, toggleElement } from "../../utilities/filter";
import { TRADE_HIDDEN, TradeHiddenEvent } from "../../../events/selection/trades/trade_hidden";
import { TRADE_HIDDEN_ALL, TradeHiddenAllEvent } from "../../../events/selection/trades/trade_hidden_all";
import { TRADE_SHOWN, TradeShownEvent } from "../../../events/selection/trades/trade_shown";
import { TRADE_SHOWN_ALL, TradeShownAllEvent } from "../../../events/selection/trades/trade_shown_all";
import { TRADE_SHOWN_ONLY, TradeShownOnlyEvent } from "../../../events/selection/trades/trade_shown_only";
import { TRADES_LOADED, TradesLoadedEvent } from "../../../events/loaded/trade/trades_loaded";

import type { Selected } from "type_aliases";

export type TradesFilterEvents = TradesLoadedEvent
                                 | TradeShownEvent
                                 | TradeHiddenEvent
                                 | TradeShownAllEvent
                                 | TradeHiddenAllEvent
                                 | TradeShownOnlyEvent;

export type TradesFilterStore = {
  [code: string]: {
    id: string
    selected: Selected,
    parentId: string,
    childIds: string[]
  }
  unclassified: {
    id: "unclassified"
    selected: 0 | 1
    parentId: null
    childIds: []
  }
}

const reduceTradesFilter: Reducer<TradesFilterStore, TradesFilterEvents> = (tradesFilter: TradesFilterStore = { unclassified: { id: "unclassified", selected: 1, parentId: null, childIds: [] } }, event = { type: null }) => {
  switch (event.type) {
    case TRADES_LOADED: {
      return {
        ...tradesFilter,
        ...event.payload.trades.reduce((tradesSoFar, trade) => {
          tradesSoFar[trade.code] = {
            id: trade.code,
            selected: 1,
            childIds: trade.childCodes,
            ...(trade.parentCode && { parentId: trade.parentCode })
          };
          return tradesSoFar;
        }, {})
      };
    }
    case TRADE_HIDDEN: {
      return toggleElement(event.payload.code, 0, tradesFilter);
    }
    case TRADE_SHOWN: {
      return toggleElement(event.payload.code, 1, tradesFilter);
    }
    case TRADE_HIDDEN_ALL: {
      return toggleAllElements(tradesFilter, 0);
    }
    case TRADE_SHOWN_ALL: {
      return toggleAllElements(tradesFilter, 1);
    }
    case TRADE_SHOWN_ONLY: {
      return toggleElement(event.payload.code, 1, toggleAllElements(tradesFilter, 0));
    }
    default: {
      return tradesFilter;
    }
  }
};

export default reduceTradesFilter;

