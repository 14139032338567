import { immerable } from "immer";
import { ApiMasterformatProgress, ApiPhotoArea, ApiProjectCostAnalysisProgress, ApiProjectReport, ApiProjectSettings } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import Masterformat from "./masterformat";
import ProjectCostAnalysisProgress from "./progress/project_cost_analysis_progress";
import ProjectReport from "./project_report";
import SimplifiedModelElement from "./simplified_model_element";
import SystemOfMeasurement, { IMPERIAL } from "./enums/system_of_measurement";
import TeamMember from "./team_member";
import ProjectWorkPackage from "./project_work_package";
import { groupProgressesByMasterformatCodeAndSortByDate } from "./progress/masterformat_progress";
import { ProjectPurposeType } from "./enums/purpose_type";

import type { AvvirApiFiles, AvvirFileIds, AvvirFiles, DateLike, Modify } from "type_aliases";
import type { ProjectMasterformatProgressDictionary } from "./progress";

type ProjectArgument = Partial<Modify<Project, {
  archivedAt?: DateLike
  costAnalysisProgresses?: ApiProjectCostAnalysisProgress[] | null
  projectReports?: ApiProjectReport[] | null
  endDate?: DateLike
  startDate?: DateLike
  scannedProjectMasterformatProgresses?: ApiMasterformatProgress[] | null
  baselineProjectMasterformatProgresses?: ApiMasterformatProgress[] | null
  currentProjectMasterformatProgresses?: ApiMasterformatProgress[] | null
  files?: AvvirFiles<ProjectPurposeType> | AvvirApiFiles<ProjectPurposeType> | AvvirFileIds<ProjectPurposeType>
}>>

export default class Project {
  constructor({
    addressCity,
    addressCountry,
    addressLine1,
    addressLine2,
    addressState,
    addressZip,
    archivedAt,
    avvirAnalysisNotes,
    firebaseOrganizationId,
    costAnalysisProgresses,
    projectReports,
    defaultFirebaseFloorId,
    endDate,
    firebaseFloorIds,
    firebaseId,
    id,
    name,
    notes,
    pricing,
    scannedProjectMasterformatProgresses,
    baselineProjectMasterformatProgresses,
    currentProjectMasterformatProgresses,
    progressNotes,
    sourceAnalysisNotes,
    startDate,
    systemOfMeasurement,
    generateMasterformatProgressEnabled,
    teamMembers,
    files,
    integrationProjectId,
    simplifiedModelElements,
    workPackages,
    settings,
    recipeIds,
    classificationCodes
  }: ProjectArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, firebaseId, firebaseOrganizationId: firebaseOrganizationId });
    addDateGetterAndSetterToDomainModel(this, "startDate", startDate || null);
    addDateGetterAndSetterToDomainModel(this, "endDate", endDate || null);
    addDateGetterAndSetterToDomainModel(this, "archivedAt", archivedAt || null);
    this.addressCity = addressCity || "";
    this.addressCountry = addressCountry || "";
    this.addressLine1 = addressLine1 || "";
    this.addressLine2 = addressLine2 || "";
    this.addressState = addressState || "";
    this.addressZip = addressZip || "";
    this.name = name || "";
    this.notes = notes || "";
    this.pricing = pricing || "";
    this.defaultFirebaseFloorId = defaultFirebaseFloorId || null;
    this.progressNotes = progressNotes || "";
    this.avvirAnalysisNotes = avvirAnalysisNotes || "";
    this.sourceAnalysisNotes = sourceAnalysisNotes || "";

    this.firebaseFloorIds = firebaseFloorIds || [];

    this.systemOfMeasurement = systemOfMeasurement || IMPERIAL;
    this.masterformatProgress.scanned = groupProgressesByMasterformatCodeAndSortByDate(scannedProjectMasterformatProgresses || []);
    this.masterformatProgress.baseline = groupProgressesByMasterformatCodeAndSortByDate(baselineProjectMasterformatProgresses || []);
    this.masterformatProgress.current = groupProgressesByMasterformatCodeAndSortByDate(currentProjectMasterformatProgresses || []);
    this.masterformatProgress.projectCostAnalysis = (costAnalysisProgresses || []).reduce((costAnalysisProgressSoFar, progress) => {
      costAnalysisProgressSoFar[progress.id] = new ProjectCostAnalysisProgress(progress);
      return costAnalysisProgressSoFar;
    }, {});
    this.generateMasterformatProgressEnabled = generateMasterformatProgressEnabled;
    this.projectReports = (projectReports || []).map((report) => new ProjectReport(report)).sort((a, b) => {
      return a.id - b.id;
    });
    this.teamMembers = teamMembers || [];
    // @ts-ignore
    this.files = files;
    this.integrationProjectId = integrationProjectId;
    this.simplifiedModelElements = simplifiedModelElements || null;
    this.workPackages = workPackages;
    this.settings = settings;
    this.recipeIds = recipeIds;
    this.classificationCodes = classificationCodes || [];
  }

  readonly id: number;
  readonly firebaseId: string;
  readonly firebaseOrganizationId: string;
  firebaseFloorIds: string[];
  defaultFirebaseFloorId: string;
  name: string;

  masterformatProgress: ProjectMasterformatProgressDictionary = { scanned: {}, baseline: {}, current: {}, projectCostAnalysis: {} };
  archivedAt: Date | null = null;

  projectReports: ProjectReport[] = [];
  addressCity: string;
  addressCountry: string;
  addressLine1: string;
  addressLine2: string;
  addressState: string;
  addressZip: string;
  notes: string;
  pricing: string;
  startDate: Date;
  endDate: Date;
  systemOfMeasurement: SystemOfMeasurement = IMPERIAL;
  progressNotes: string;
  avvirAnalysisNotes: string;
  sourceAnalysisNotes: string;
  generateMasterformatProgressEnabled: boolean;
  files?: AvvirFileIds<ProjectPurposeType>;
  photoAreas?: ApiPhotoArea[];
  teamMembers?: TeamMember[];
  integrationProjectId?: number;
  workPackages?: ProjectWorkPackage[];
  simplifiedModelElements?: SimplifiedModelElement[];
  settings?: ApiProjectSettings;
  recipeIds: number[];
  classificationCodes: Omit<Masterformat, "version">[];

  static readonly [immerable] = true;
}
