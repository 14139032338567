import { Vector3 } from "three";
import Avvir, { ApiBuiltStatus, ApiScannedElement, UserActionType } from "avvir";

import DateConverter from "../../../services/converters/date_converter";
import ElementEditAction from "../../../models/domain/element_edit_action";
import elementsEdited, { ELEMENTS_EDITED } from "../../../events/viewer/elements_edited";
import getFloorId from "../../../services/getters/floor_getters/get_floor_id";
import getProjectIdFromLocation from "../../../services/getters/location_metadata/get_project_id_from_location";
import getScanDataset from "../../../services/getters/scan_dataset_getters/get_scan_dataset";
import getScanDatasetId from "../../../services/getters/scan_dataset_getters/get_scan_dataset_id";
import getUser from "../../../services/getters/base_getters/get_user";
import PlannedBuildingElement from "../../../models/domain/planned_building_element";
import recordUserActions from "../../record_user_actions";
import { AvvirThunk } from "../../make_eventful_action";
import { DETECTED, DISMISSED } from "../../../models/domain/enums/deviation_status";
import { DEVIATED } from "../../../models/domain/enums/scan_label";

import type { Vector3Like } from "type_aliases";

const isVector3 = (vector: Vector3Like): vector is Vector3 => {
  return (vector as Vector3)?.isVector3;
};

function makeDeviationUpdateRecords(originalElements: PlannedBuildingElement[], scanDate: Date, newDeviation: Vector3Like): ApiScannedElement<ApiBuiltStatus>[] {
  const newDeviationVector = isVector3(newDeviation) ? newDeviation : new Vector3(newDeviation.x, newDeviation.y, newDeviation.z);
  return originalElements.map(element => {
    // We have to do this because for some reason, not all vectors are of type Vector3 but rather a Vector3Like without the instance methods.
    //TODO: Find out where these are being stored and convert them there.
    const deviation = PlannedBuildingElement.getDeviation(element, scanDate)?.deviationVectorMeters as Vector3Like;
    let deviationVector = isVector3(deviation) ? deviation : new Vector3(deviation?.x || 0, deviation?.y || 0, deviation?.z || 0);
    if (deviationVector.equals(newDeviationVector)) {
      if (DateConverter.isOnDay(element.builtAt, scanDate) || DateConverter.isOnDay(element.fixedAt, scanDate)) {
        return null;
      }
    }

    let newStatus = PlannedBuildingElement.getDeviation(element, scanDate)?.status || DETECTED;
    if (newStatus === DISMISSED) {
      newStatus = DETECTED;
    }
    return {
      globalId: element.globalId,
      scanLabel: DEVIATED,
      deviation: {
        ...PlannedBuildingElement.getDeviation(element, scanDate),
        status: newStatus,
        deviationVectorMeters: newDeviationVector,
        deviationMeters: newDeviationVector.length(),
        clashing: false
      }
    };
  }).filter(element => element != null);
}

const saveUpdatedDeviations = (elementsToUpdate: PlannedBuildingElement[], newDeviation: Vector3Like): AvvirThunk<ReturnType<SaveUpdatedDeviationsAction["perform"]>, ElementEditAction> => {
  return (dispatch, getState) => {
    const floorId = getFloorId(getState(), {});
    return dispatch(new SaveUpdatedDeviationsAction(floorId, elementsToUpdate, newDeviation));
  };
};

export class SaveUpdatedDeviationsAction extends ElementEditAction {
  elementsToUpdate: PlannedBuildingElement[];
  newDeviation: Vector3Like;

  constructor(floorId: string, elementsToUpdate: PlannedBuildingElement[], newDeviation: Vector3Like) {
    super("Position", ELEMENTS_EDITED, floorId);
    this.elementsToUpdate = elementsToUpdate;
    this.newDeviation = newDeviation;
  }

  perform(dispatch, getState) {
    this.payload.previousElementStates = this.elementsToUpdate;
    if (this.elementsToUpdate.length) {
      const state = getState();
      const projectId = getProjectIdFromLocation(state, {});
      const scanDatasetId = getScanDatasetId(state, {});
      const scanDataset = getScanDataset(state, {});
      const user = getUser(state, {});

      const deviationUpdateRecords = makeDeviationUpdateRecords(this.elementsToUpdate, scanDataset.scanDate, this.newDeviation);
      if (!deviationUpdateRecords.length) {
        return Promise.resolve();
      }
      // TODO(dlb): Rename ApiScannedElement<BuiltStatus[]> to something better (e.g. ApiElementStatusUpdate or something)
      return Avvir.api.elements.updatePlannedBuildingElementsForViewer({ projectId, floorId: this.payload.floorId, scanDatasetId }, deviationUpdateRecords, false, user)
        .then((updatedApiElements) => {
          this.payload.nextElementStates = updatedApiElements.map(element => new PlannedBuildingElement(element));
          dispatch(elementsEdited(this.payload.floorId, this.payload.previousElementStates, this.payload.nextElementStates));
          dispatch(recordUserActions(UserActionType.ELEMENTS_STATUSES_UPDATED, this.payload.nextElementStates, null));
        });
    } else {
      return Promise.resolve();
    }
  }
}

export default saveUpdatedDeviations;
