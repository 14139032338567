export enum ProjectPurposeType {
  OTHER = "other",
  RAW_PROJECT_BIM = "rawProjectBim",
  PROJECT_FOLDER_ZIP = "projectFolderZip",
  PROJECT_AREAS_NWD = "projectAreasNwd",
  PROJECT_AREAS_IFC = "projectAreasIfc",
  PROJECT_AREAS_SVF = "projectAreasSvf",
  PROJECT_AREAS_OBJ = "projectAreasObj",
  PROJECT_EXPORT_REPORT = "projectExportReport",
  PROJECT_REPORT_SCREENSHOT = "projectReportScreenshot",
  PROJECT_REPORT_MINIMAP = "projectReportMinimap",
  PROJECT_REPORT_ZOOMED_MINIMAP = "projectReportZoomedMinimap",
}

export enum FloorPurposeType {
  OTHER = "other",
  BIM_IFC = "bimIfc",
  BIM_NWD = "bimNwd",
  GRID_IFC = "gridIfc",
  BIM_MESH_GLB = "bimMeshGlb",
  BIM_MESH_OBJ = "bimMeshObj",
  BIM_TEXTURE_MTL = "bimTextureMtl",
  VIEWER_BIM_MESH_OBJ = "viewerBimMeshObj",
  BIM_MINIMAP = "bimMinimap",
  PLANNED_CLOUD_ZIP = "plannedCloudZip",
  SVF = "svf"
}

export enum ScanDatasetPurposeType {
  OTHER = "other",
  /** @deprecated */
  RAW_SCAN = "rawScan",
  SCANNER_PATH = "scannerPath",
  FLOOR_FLATNESS_TOPO_MAP = "floorFlatnessTopoMap",
  BUILT_NOT_BUILT_BIM_IFC = "builtNotBuiltBimIfc",
  NEEDS_FURTHER_ANALYSIS = "needsFurtherAnalysis",
  PREPROCESSED_SCAN = "preprocessedScan",
  PREPROCESSED_SCAN_E57 = "preprocessedScanE57",
  INCLUDED_BIM_IFC = "includedBimIfc",
  POTREE = "potree",
  ELEMENT_SNAPSHOT = "elementSnapshot",
  SITE_CUBE_PHOTO = "siteCubePhoto",
  DOWNSAMPLED_SCAN = "downsampledScan",
  INGESTED_SCAN = "ingestedScan",
  DEVIATIONS_SCAN_BCF = "deviationsScanBcf",
  DEVIATIONS_BIM_IFC = "deviationsBimIfc",
  NOT_BUILT_IFC = "notBuiltIfc"
}

export enum PhotoAreaPurposeType {
  OTHER = "other",
  MINIMAP = "minimap",
  THREE_SIXTY_PHOTO = "threeSixtyPhoto"
}

export type ProjectTypeKeys = keyof typeof ProjectPurposeType;
export type FloorTypeKeys = keyof typeof FloorPurposeType;
export type ScanDatasetTypeKeys = keyof typeof ScanDatasetPurposeType;
export type PhotoAreaTypeKeys = keyof typeof PhotoAreaPurposeType;

export type PurposeTypeKeys = ProjectTypeKeys | FloorTypeKeys | ScanDatasetTypeKeys | PhotoAreaTypeKeys;
export type PurposeType = ProjectPurposeType | FloorPurposeType | ScanDatasetPurposeType | PhotoAreaPurposeType;
type ProjectTypeMap = {
  OTHER: ProjectPurposeType.OTHER
  RAW_PROJECT_BIM: ProjectPurposeType.RAW_PROJECT_BIM
  PROJECT_FOLDER_ZIP: ProjectPurposeType.PROJECT_FOLDER_ZIP
  PROJECT_AREAS_NWD: ProjectPurposeType.PROJECT_AREAS_NWD
  PROJECT_AREAS_IFC: ProjectPurposeType.PROJECT_AREAS_IFC
  PROJECT_AREAS_SVF: ProjectPurposeType.PROJECT_AREAS_SVF,
  PROJECT_AREAS_OBJ: ProjectPurposeType.PROJECT_AREAS_OBJ,
  PROJECT_EXPORT_REPORT: ProjectPurposeType.PROJECT_EXPORT_REPORT,
  PROJECT_REPORT_SCREENSHOT: ProjectPurposeType.PROJECT_REPORT_SCREENSHOT,
  PROJECT_REPORT_MINIMAP: ProjectPurposeType.PROJECT_REPORT_MINIMAP,
  PROJECT_REPORT_ZOOMED_MINIMAP: ProjectPurposeType.PROJECT_REPORT_ZOOMED_MINIMAP,
};

type FloorTypeMap = {
  OTHER: FloorPurposeType.OTHER
  BIM_IFC: FloorPurposeType.BIM_IFC
  BIM_NWD: FloorPurposeType.BIM_NWD
  GRID_IFC: FloorPurposeType.GRID_IFC
  BIM_MESH_GLB: FloorPurposeType.BIM_MESH_GLB
  BIM_MESH_OBJ: FloorPurposeType.BIM_MESH_OBJ
  BIM_TEXTURE_MTL: FloorPurposeType.BIM_TEXTURE_MTL
  VIEWER_BIM_MESH_OBJ: FloorPurposeType.VIEWER_BIM_MESH_OBJ
  BIM_MINIMAP: FloorPurposeType.BIM_MINIMAP
  PLANNED_CLOUD_ZIP: FloorPurposeType.PLANNED_CLOUD_ZIP
  SVF: FloorPurposeType.SVF
};

type ScanDatasetTypeMap = {
  OTHER: ScanDatasetPurposeType.OTHER
  /** @deprecated */
  RAW_SCAN: ScanDatasetPurposeType.RAW_SCAN
  SCANNER_PATH: ScanDatasetPurposeType.SCANNER_PATH
  FLOOR_FLATNESS_TOPO_MAP: ScanDatasetPurposeType.FLOOR_FLATNESS_TOPO_MAP
  BUILT_NOT_BUILT_BIM_IFC: ScanDatasetPurposeType.BUILT_NOT_BUILT_BIM_IFC
  NEEDS_FURTHER_ANALYSIS: ScanDatasetPurposeType.NEEDS_FURTHER_ANALYSIS
  PREPROCESSED_SCAN: ScanDatasetPurposeType.PREPROCESSED_SCAN
  PREPROCESSED_SCAN_E57: ScanDatasetPurposeType.PREPROCESSED_SCAN_E57
  INCLUDED_BIM_IFC: ScanDatasetPurposeType.INCLUDED_BIM_IFC
  POTREE: ScanDatasetPurposeType.POTREE
  ELEMENT_SNAPSHOT: ScanDatasetPurposeType.ELEMENT_SNAPSHOT
  SITE_CUBE_PHOTO: ScanDatasetPurposeType.SITE_CUBE_PHOTO
  DOWNSAMPLED_SCAN: ScanDatasetPurposeType.DOWNSAMPLED_SCAN
  INGESTED_SCAN: ScanDatasetPurposeType.INGESTED_SCAN,
  DEVIATIONS_SCAN_BCF: ScanDatasetPurposeType.DEVIATIONS_SCAN_BCF
  DEVIATIONS_BIM_IFC: ScanDatasetPurposeType.DEVIATIONS_BIM_IFC
  NOT_BUILT_IFC: ScanDatasetPurposeType.NOT_BUILT_IFC
};

type PhotoAreaTypeMap = {
  OTHER: PhotoAreaPurposeType.OTHER
  MINIMAP: PhotoAreaPurposeType.MINIMAP
  THREE_SIXTY_PHOTO: PhotoAreaPurposeType.THREE_SIXTY_PHOTO
};

// noinspection JSDeprecatedSymbols
const PurposeTypeMap: ProjectTypeMap & Omit<FloorTypeMap, "OTHER"> & Omit<ScanDatasetTypeMap, "OTHER"> & Omit<PhotoAreaTypeMap, "OTHER"> = {
  MINIMAP: PhotoAreaPurposeType.MINIMAP,
  THREE_SIXTY_PHOTO: PhotoAreaPurposeType.THREE_SIXTY_PHOTO,

  /** @deprecated */
  RAW_SCAN: ScanDatasetPurposeType.RAW_SCAN,
  DOWNSAMPLED_SCAN: ScanDatasetPurposeType.DOWNSAMPLED_SCAN,

  SCANNER_PATH: ScanDatasetPurposeType.SCANNER_PATH,
  FLOOR_FLATNESS_TOPO_MAP: ScanDatasetPurposeType.FLOOR_FLATNESS_TOPO_MAP,
  BUILT_NOT_BUILT_BIM_IFC: ScanDatasetPurposeType.BUILT_NOT_BUILT_BIM_IFC,
  NEEDS_FURTHER_ANALYSIS: ScanDatasetPurposeType.NEEDS_FURTHER_ANALYSIS,
  PREPROCESSED_SCAN: ScanDatasetPurposeType.PREPROCESSED_SCAN,
  PREPROCESSED_SCAN_E57: ScanDatasetPurposeType.PREPROCESSED_SCAN_E57,
  INCLUDED_BIM_IFC: ScanDatasetPurposeType.INCLUDED_BIM_IFC,
  POTREE: ScanDatasetPurposeType.POTREE,
  ELEMENT_SNAPSHOT: ScanDatasetPurposeType.ELEMENT_SNAPSHOT,
  SITE_CUBE_PHOTO: ScanDatasetPurposeType.SITE_CUBE_PHOTO,
  INGESTED_SCAN: ScanDatasetPurposeType.INGESTED_SCAN,
  DEVIATIONS_SCAN_BCF: ScanDatasetPurposeType.DEVIATIONS_SCAN_BCF,
  DEVIATIONS_BIM_IFC: ScanDatasetPurposeType.DEVIATIONS_BIM_IFC,
  NOT_BUILT_IFC: ScanDatasetPurposeType.NOT_BUILT_IFC,

  BIM_IFC: FloorPurposeType.BIM_IFC,
  BIM_NWD: FloorPurposeType.BIM_NWD,
  GRID_IFC: FloorPurposeType.GRID_IFC,
  BIM_MESH_GLB: FloorPurposeType.BIM_MESH_GLB,
  BIM_MESH_OBJ: FloorPurposeType.BIM_MESH_OBJ,
  BIM_TEXTURE_MTL: FloorPurposeType.BIM_TEXTURE_MTL,
  VIEWER_BIM_MESH_OBJ: FloorPurposeType.VIEWER_BIM_MESH_OBJ,
  BIM_MINIMAP: FloorPurposeType.BIM_MINIMAP,
  PLANNED_CLOUD_ZIP: FloorPurposeType.PLANNED_CLOUD_ZIP,
  SVF: FloorPurposeType.SVF,

  OTHER: ProjectPurposeType.OTHER,
  RAW_PROJECT_BIM: ProjectPurposeType.RAW_PROJECT_BIM,
  PROJECT_FOLDER_ZIP: ProjectPurposeType.PROJECT_FOLDER_ZIP,
  PROJECT_AREAS_NWD: ProjectPurposeType.PROJECT_AREAS_NWD,
  PROJECT_AREAS_IFC: ProjectPurposeType.PROJECT_AREAS_IFC,
  PROJECT_AREAS_SVF: ProjectPurposeType.PROJECT_AREAS_SVF,
  PROJECT_AREAS_OBJ: ProjectPurposeType.PROJECT_AREAS_OBJ,
  PROJECT_EXPORT_REPORT: ProjectPurposeType.PROJECT_EXPORT_REPORT,
  PROJECT_REPORT_SCREENSHOT: ProjectPurposeType.PROJECT_REPORT_SCREENSHOT,
  PROJECT_REPORT_MINIMAP: ProjectPurposeType.PROJECT_REPORT_MINIMAP,
  PROJECT_REPORT_ZOOMED_MINIMAP: ProjectPurposeType.PROJECT_REPORT_ZOOMED_MINIMAP,
};


export const isPurposeType = (type: any): type is PurposeType => Object.values(PurposeTypeMap)
  .some(purposeType => type === purposeType);

export const OTHER = ProjectPurposeType.OTHER;
export const RAW_PROJECT_BIM = ProjectPurposeType.RAW_PROJECT_BIM;
export const PROJECT_FOLDER_ZIP = ProjectPurposeType.PROJECT_FOLDER_ZIP;
export const PROJECT_AREAS_NWD = ProjectPurposeType.PROJECT_AREAS_NWD;
export const PROJECT_AREAS_IFC = ProjectPurposeType.PROJECT_AREAS_IFC;
export const PROJECT_AREAS_SVF = ProjectPurposeType.PROJECT_AREAS_SVF;

// noinspection JSDeprecatedSymbols
/** @deprecated */
export const RAW_SCAN = ScanDatasetPurposeType.RAW_SCAN;

export const SCANNER_PATH = ScanDatasetPurposeType.SCANNER_PATH;
export const FLOOR_FLATNESS_TOPO_MAP = ScanDatasetPurposeType.FLOOR_FLATNESS_TOPO_MAP;
export const BUILT_NOT_BUILT_BIM_IFC = ScanDatasetPurposeType.BUILT_NOT_BUILT_BIM_IFC;
export const NEEDS_FURTHER_ANALYSIS = ScanDatasetPurposeType.NEEDS_FURTHER_ANALYSIS;
export const PREPROCESSED_SCAN = ScanDatasetPurposeType.PREPROCESSED_SCAN;
export const PREPROCESSED_SCAN_E57 = ScanDatasetPurposeType.PREPROCESSED_SCAN_E57;
export const INCLUDED_BIM_IFC = ScanDatasetPurposeType.INCLUDED_BIM_IFC;
export const POTREE = ScanDatasetPurposeType.POTREE;
export const DEVIATIONS_BIN_IFC = ScanDatasetPurposeType.DEVIATIONS_BIM_IFC;
export const NOT_BUILT_IFC = ScanDatasetPurposeType.NOT_BUILT_IFC;
export const BIM_IFC = FloorPurposeType.BIM_IFC;
export const BIM_NWD = FloorPurposeType.BIM_NWD;
export const GRID_IFC = FloorPurposeType.GRID_IFC;
export const BIM_MESH_GLB = FloorPurposeType.BIM_MESH_GLB;
export const BIM_MESH_OBJ = FloorPurposeType.BIM_MESH_OBJ;
export const BIM_TEXTURE_MTL = FloorPurposeType.BIM_TEXTURE_MTL;
export const BIM_MINIMAP = FloorPurposeType.BIM_MINIMAP;
export const VIEWER_BIM_MESH_OBJ = FloorPurposeType.VIEWER_BIM_MESH_OBJ;
export const PLANNED_CLOUD_ZIP = FloorPurposeType.PLANNED_CLOUD_ZIP;
export const SVF = FloorPurposeType.SVF;

export const MINIMAP = PhotoAreaPurposeType.MINIMAP;
export const THREE_SIXTY_PHOTO = PhotoAreaPurposeType.THREE_SIXTY_PHOTO;
export const PROJECT_EXPORT_REPORT = ProjectPurposeType.PROJECT_EXPORT_REPORT;

// noinspection JSDeprecatedSymbols
export type UploadHistoryPurposeTypes = typeof RAW_SCAN | typeof OTHER

// noinspection JSDeprecatedSymbols
export const uploadHistoryPurposeTypes = [
  /** @deprecated */
  RAW_SCAN,
  OTHER
];

export const floorPurposeTypes = [
  FloorPurposeType.OTHER,
  BIM_IFC,
  BIM_NWD,
  GRID_IFC,
  BIM_MESH_GLB,
  BIM_MESH_OBJ,
  BIM_TEXTURE_MTL,
  BIM_MINIMAP,
  VIEWER_BIM_MESH_OBJ,
  PLANNED_CLOUD_ZIP,
  SVF,
];

export const projectPurposeTypes = [
  ProjectPurposeType.OTHER,
  ProjectPurposeType.PROJECT_AREAS_IFC,
  ProjectPurposeType.PROJECT_AREAS_SVF,
  ProjectPurposeType.PROJECT_AREAS_NWD,
  ProjectPurposeType.PROJECT_AREAS_OBJ,
  ProjectPurposeType.PROJECT_FOLDER_ZIP,
  ProjectPurposeType.RAW_PROJECT_BIM,
  ProjectPurposeType.PROJECT_EXPORT_REPORT,
  ProjectPurposeType.PROJECT_REPORT_SCREENSHOT,
  ProjectPurposeType.PROJECT_REPORT_MINIMAP,
  ProjectPurposeType.PROJECT_REPORT_ZOOMED_MINIMAP,
];


const isEqual = <Type>(a: Type) => (b: Type) => a === b;

export const isFloorPurposeType = (type: PurposeType): type is FloorPurposeType => floorPurposeTypes.some(isEqual(type));
export const isProjectPurposeType = (type: PurposeType): type is ProjectPurposeType => projectPurposeTypes.some(isEqual(type));
export const isScanPurposeType = (type: PurposeType): type is ScanDatasetPurposeType => !isFloorPurposeType(type) && !isProjectPurposeType(type) && isPurposeType(type);

export default PurposeTypeMap as ProjectTypeMap & Omit<FloorTypeMap, "OTHER"> & Omit<ScanDatasetTypeMap, "OTHER"> & Omit<PhotoAreaTypeMap, "OTHER">;
