import Avvir from "avvir";
import checkFetchStatus from "../deprecated_gateway_api/check_fetch_status";
import { httpGetHeaders } from "../deprecated_gateway_api/request_headers";
import queryString from "query-string";

export interface OAuth2AccessTokenResponse {
  accessToken: string,
  tokenType: string,
  expiresIn: number,
  refreshToken: string,
}

export default class HxAuthApi {
  static getBaseUrl(clientId: string) {
    return "https://hxauth.com/auth/realms/" + clientId;
  }

  static getLoginUrl(clientId: string, state: string) {
    return `${HxAuthApi.getBaseUrl(clientId)}/protocol/openid-connect/auth?response_type=code&client_id=${clientId}&redirect_uri=${window.location.origin}/sign-in/sso&state=${state}`;
  }

  static createAccessToken(realm: string, authorizationCode: string): Promise<OAuth2AccessTokenResponse> {
    const params = queryString.stringify({
      realm,
      code: authorizationCode,
      redirectUri: `${window.location.origin}/sign-in/sso`
    });
    return (fetch(`${Avvir.api.other.baseUrl()}/hxauth/access-token?${params}`,
      {
        method: "GET",
        headers: httpGetHeaders,
      })
      .then(checkFetchStatus) as Promise<OAuth2AccessTokenResponse>);
  }

  static refreshAccessToken(refreshToken: string): Promise<OAuth2AccessTokenResponse> {
    const params = queryString.stringify({
      refreshToken,
    });
    return (fetch(`${Avvir.api.other.baseUrl()}/hxauth/refresh-token?${params}`,
      {
        method: "GET",
        headers: httpGetHeaders,
      })
      .then(checkFetchStatus) as Promise<OAuth2AccessTokenResponse>);
  }
}
