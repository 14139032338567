import { UserRole } from "avvir";
import { AvvirEvent } from "type_aliases";
import {decodeAvvirCustomFirebaseToken} from "../../services/firebase_services/firebase_tokens";
import _ from "underscore";

export type FirebaseUserSignedInEvent = AvvirEvent<typeof FIREBASE_USER_SIGNED_IN, {
  email: string,
  uid: string,
  displayName: string,
  role: UserRole,
  idToken: string
}>

const firebaseUserSignedIn = ({ email, uid, displayName}: { email: string, uid: string, displayName: string }, idToken: string, role: UserRole): FirebaseUserSignedInEvent => {
  const decoded = decodeAvvirCustomFirebaseToken(idToken);

  return {
    type: FIREBASE_USER_SIGNED_IN,
    payload: {
      email: _.isEmpty(email) ? decoded.sub : email,
      uid,
      displayName,
      role: role == null ? decoded.role : role,
      idToken
    }
  };
}

export const FIREBASE_USER_SIGNED_IN = "firebase_user_signed_in";

export default firebaseUserSignedIn;
