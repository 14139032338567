import Avvir, { AssociationIds, ProgressType, ResponseError } from "avvir";

import floorMasterformatProgressLoaded, { FloorMasterformatProgressLoadedEvent } from "../../events/loaded/floors/floor_masterformat_progress_loaded";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import MasterformatProgressConverter from "../../services/converters/masterformat_progress_converter";
import projectNotFound, { ProjectNotFoundEvent } from "../../events/notifications/failures/project_not_found";

const loadFloorMasterformatProgress = (associationIds: AssociationIds): AvvirThunk<Promise<void>, FloorMasterformatProgressLoadedEvent | ProjectNotFoundEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;

    return Promise.all([
        Avvir.api.floors.getMasterformatProgress(associationIds, ProgressType.SCANNED, user)
          .then(MasterformatProgressConverter.convertScanDatesFromInstant),
        Avvir.api.floors.getMasterformatProgress(associationIds, ProgressType.CURRENT, user)
          .then(MasterformatProgressConverter.convertScanDatesFromInstant),
        Avvir.api.floors.getMasterformatProgress(associationIds, ProgressType.BASELINE, user)
          .then(MasterformatProgressConverter.convertScanDatesFromInstant)
      ])
      .then(([scanned, current, baseline]) => {
        dispatch(floorMasterformatProgressLoaded(associationIds, { scanned, baseline, current }));
      }).catch((error) => {
        if (error instanceof ResponseError && error.status === 404 && error.message.toLowerCase().includes("project does not exist")) {
          dispatch(projectNotFound());
        } else {
          throw error;
        }
      });
  };
};

export default makeEventfulAction("loadFloorMasterformatProgress", loadFloorMasterformatProgress);
