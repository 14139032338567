import "./polyfill";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import firebase from "firebase";
import { isFirebaseUser, isGatewayUser, UserRole } from "avvir";

import ApplicationContentUnderNavBar from "./application_content_under_nav_bar";
import authorizeFirebaseUser from "../../actions/sign_in_page/authorize_firebase_user";
import firebaseService from "../../services/firebase_services/firebase";
import firebaseSignedInWithCustomToken, { FirebaseSignedInWithCustomTokenEvent } from "../../events/auth_events/firebase_signed_in_with_custom_token";
import firebaseUserSignedIn, { FirebaseUserSignedInEvent } from "../../events/auth_events/firebase_user_signed_in";
import gatewayUserSignedIn, { GatewayUserSignedInEvent } from "../../events/auth_events/gateway_user_signed_in";
import getIsClientUserPage from "../../services/getters/location_metadata/get_is_client_user_page";
import getIsSuperadminPage from "../../services/getters/location_metadata/get_is_superadmin_page";
import getPageName from "../../services/getters/location_metadata/get_page_name";
import getPageNotFound from "../../services/getters/location_metadata/get_page_not_found";
import getProject from "../../services/getters/project_getters/get_project";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import getQueryParamIsEmbedded from "../../services/getters/location_metadata/get_query_param_is_embedded";
import getToastNotification from "../../services/getters/get_toast_notification";
import getUser from "../../services/getters/base_getters/get_user";
import getUserAccount from "../../services/getters/user_getters/get_user_account";
import getUserRole from "../../services/getters/user_getters/get_user_role";
import loadUserAccount from "../../actions/react_query/user/load_user_account";
import loadUserPermissions from "../../actions/user/load_user_permissions";
import NavBar from "../nav_bar/nav_bar";
import OopsPage from "../other_pages/oops_page";
import refreshFirebaseToken from "../../actions/sign_in_page/refresh_firebase_token";
import RunningProcessesOverlay from "../running_processes_overlay";
import setMixPanelUser from "../../actions/set_mixpanel_user";
import signOutUser from "../../actions/sign_in_page/sign_out_user";
import SnackbarNotification from "../snackbar_notification";
import ToastNotification from "../toast_notification";
import toProject, { ToProjectEvent } from "../../events/routing/to_project";
import { ApiFailureEvent } from "../../events/notifications/failures/api_failure";
import { Dispatch } from "type_aliases";
import { LaunchDarklyClient } from "../launch_darkly_client";
import { ReduxStore } from "../../services/reducers/root_reducer";
import toSuperadminOrganizations, { ToSuperadminOrganizationsEvent } from "../../events/routing/superadmin/to_superadmin_organizations";
import hxAuthUserSignedIn, { HxAuthUserSignedInEvent } from "../../events/auth_events/hxauth_user_signed_in";
import refreshHxAuthToken from "../../actions/hxauth/refresh_hxauth_token";
import { UserSignedOutEvent } from "../../events/auth_events/user_signed_out";

type State = { hasError: boolean };
export type Props = ReturnType<typeof mapStateToProps>
                    & ReturnType<typeof mapDispatchToProps>

export class Application extends Component<Props, State> {
  state: State = {
    hasError: false,
  };

  removeAuthStateListener: () => void;

  normalizeFirebaseUser(firebaseUser: firebase.User) {
    return {
      email: firebaseUser.email || firebaseUser.uid, // Superadmins often have a `null` email, but their uid is their email address
      role: this.props.userRole,
      projectName: "SUPERADMIN_ALL_ACCESS"
    };
  }

  disableErrorOverlayForTests() {
    if (process.env.NODE_ENV === "test") {
      const styleElement = document.createElement("style");
      styleElement.appendChild(document.createTextNode("body > iframe { display: none; }"));
      document.getElementsByTagName("head")[0].appendChild(styleElement);
    }
  }

  componentDidMount() {
    const hxAuth = window.localStorage.getItem("hxauth");
    const jwt = window.localStorage.getItem("gatewayJwt");
    if (hxAuth) {
      const {accessToken, refreshToken} = JSON.parse(hxAuth);
      this.props.hxAuthUserSignedIn(accessToken, refreshToken);
      this.props.loadUserAccount();
      this.props.loadUserPermissions();
      window.setInterval(this.props.refreshHxAuthToken, 10 * 1000);
    } else if (jwt) {
      this.props.gatewayUserSignedIn(jwt, null);
      this.props.loadUserAccount();
      this.props.loadUserPermissions();
    }

    if (process.env.NODE_ENV !== "production") {
      const storedFirebaseUser = window.localStorage.getItem("testFirebaseUser");
      if (storedFirebaseUser) {
        const user = JSON.parse(storedFirebaseUser) as firebase.User;
        this.props.firebaseSignedInWithCustomToken(window.localStorage.getItem("testFirebaseStorageToken"));
        this.props.firebaseUserSignedIn(user, window.localStorage.getItem("testFirebaseIdToken"), UserRole.SUPERADMIN);
      }
    }

    this.disableErrorOverlayForTests();
    this.removeAuthStateListener = firebaseService.auth()
      .onAuthStateChanged((firebaseUser) => {
        // TODO there is a bug in testcafe when this isn't always called. If this is fixed remove --skip-js-errors from yarn:integration
        if (firebaseUser && !isFirebaseUser(this.props.user)) {
          const userRole = isGatewayUser(this.props.user) ? this.props.userRole : UserRole.SUPERADMIN;
          return firebaseUser.getIdToken(false)
            .then((idToken) => {
              this.props.firebaseUserSignedIn(firebaseUser, idToken, userRole);
              // if (userRole === UserRole.SUPERADMIN) {
              //   this.props.toSuperadminOrganizations()
              // }
              this.props.refreshFirebaseToken(firebaseUser)
                .then(() => {
                  if (this.props.userRole === UserRole.SUPERADMIN) {
                    this.props.setMixPanelUser(this.normalizeFirebaseUser(firebaseUser));
                  }

                  this.props.authorizeFirebaseUser().then(() => {
                    return firebaseService.auth().onAuthStateChanged((user) => {
                      if (!user) {
                        return this.props.signOutUser();
                      }
                      return Promise.resolve();
                    });
                  });
                });
            });
        } else {
          return null;
        }
      });
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);
    return {
      hasError: true,
      error,
      info: errorInfo
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const emailUpdatedAndExists = this.props.userAccount?.email && prevProps.userAccount?.email !== this.props.userAccount?.email;
    const projectUpdatedAndExists = this.props.project?.name && prevProps.project?.name !== this.props.project?.name;
    if (emailUpdatedAndExists) {
      this.props.loadUserPermissions();
    }
    if ((projectUpdatedAndExists && this.props.userAccount.email) || (emailUpdatedAndExists && this.props.project?.name)) {
      const { email, role } = this.props.userAccount;
      this.props.setMixPanelUser({ email, role, projectName: this.props.project.name });
    }
  }

  componentWillUnmount() {
    this.removeAuthStateListener && this.removeAuthStateListener();
  }

  render() {
    if (this.state.hasError) {
      return <OopsPage/>;
    }

    const notification = this.props.toastNotification;
    const toastNotification = notification != null && !notification.manualClose && notification.panel == null ? <ToastNotification/> : null;
    const snackbar = notification != null && notification.manualClose && notification.panel == null ? <SnackbarNotification/> : null;
    return <div>
      {this.props.embedded ? null : <NavBar/>}
      <LaunchDarklyClient/>
      {toastNotification}
      {snackbar}
      <ApplicationContentUnderNavBar
        isClientUserPage={this.props.isClientUserPage}
        pageName={this.props.pageName}
        notFound={this.props.pageNotFound}
        noUser={this.props.user == null}
        isSuperadminPage={this.props.isSuperadminPage}
        userRole={this.props.userRole}
        defaultProject={this.props.user?.account?.projectId}
      />
      <RunningProcessesOverlay/>
    </div>;
  }
}

const mapStateToProps = (state: ReduxStore, props) => ({
  isSuperadminPage: getIsSuperadminPage(state, props),
  isClientUserPage: getIsClientUserPage(state, props),
  pageName: getPageName(state, props),
  pageNotFound: getPageNotFound(state, props),
  user: getUser(state, props),
  userRole: getUserRole(state, props),
  toastNotification: getToastNotification(state, props),
  projectId: getProjectIdFromLocation(state, props),
  project: getProject(state, props),
  userAccount: getUserAccount(state, props),
  embedded: getQueryParamIsEmbedded(state, props)
});

type DispatchedEvents =
  | ReturnType<typeof authorizeFirebaseUser>
  | FirebaseUserSignedInEvent
  | FirebaseSignedInWithCustomTokenEvent
  | GatewayUserSignedInEvent
  | ReturnType<typeof refreshFirebaseToken>
  | ReturnType<typeof setMixPanelUser>
  | ToProjectEvent
  | ApiFailureEvent
  | ReturnType<typeof signOutUser>
  | ReturnType<typeof loadUserAccount>
  | ToSuperadminOrganizationsEvent
  | HxAuthUserSignedInEvent
  | UserSignedOutEvent;

const mapDispatchToProps = (dispatch: Dispatch<DispatchedEvents>) => bindActionCreators({
  authorizeFirebaseUser,
  firebaseUserSignedIn,
  firebaseSignedInWithCustomToken,
  gatewayUserSignedIn,
  hxAuthUserSignedIn,
  refreshFirebaseToken,
  setMixPanelUser,
  toProject,
  signOutUser,
  loadUserAccount,
  loadUserPermissions,
  toSuperadminOrganizations,
  refreshHxAuthToken,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Application);
