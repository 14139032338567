import Avvir, { ApiMasterformatProgress, AssociationIds } from "avvir";

import checkRunningGatewayProcess from "../check_running_gateway_process";
import DateConverter from "../../services/converters/date_converter";
import loadFloorMasterformatProgress from "./load_floor_masterformat_progress";
import loadProjectMasterformatProgress from "./load_project_masterformat_progress";
import updateProjectMasterformatProgressCompleted, { UpdateProjectMasterformatProgressCompletedEvent } from "../../events/update_project_masterformat_progress_completed";
import updateProjectMasterformatProgressStarted, { UpdateProjectMasterformatProgressStartedEvent } from "../../events/update_project_masterformat_progress_started";
import { AvvirThunk } from "../make_eventful_action";
import { AvvirThunkEvents } from "type_aliases";

const dateFormatter = DateConverter.getDateFormatter("YYYY-MM-DDTHH:mm:ss");

type DispatchedEvents =
  | UpdateProjectMasterformatProgressStartedEvent
  | UpdateProjectMasterformatProgressCompletedEvent
  | AvvirThunkEvents<typeof loadProjectMasterformatProgress>
  | AvvirThunkEvents<typeof checkRunningGatewayProcess>

function updateProgressReport(associationIds: AssociationIds): AvvirThunk<Promise<ApiMasterformatProgress[]>, DispatchedEvents> {
  return (dispatch, getState) => {
    const now = new Date();
    // BSI-202: Fix scans in the near future not getting calculated in a hacky way by adding 1 year to the current date
    const reportDate = new Date();
    reportDate.setFullYear(reportDate.getFullYear() + 1);
    const formattedReportDate = dateFormatter.formatUTC(reportDate) + "Z";
    const masterformatVersion = 2016;
    const state = getState();
    const hasFloor = associationIds.floorId != null;

    return (hasFloor ?
            Avvir.api.floors.generateMasterformatProgress(associationIds, formattedReportDate, masterformatVersion, state.user) :
            Avvir.api.projects.generateMasterformatProgress(associationIds, formattedReportDate, masterformatVersion, state.user))
      .then((runningProcess) => {
        dispatch(updateProjectMasterformatProgressStarted(associationIds, now, runningProcess.id));
        return dispatch(checkRunningGatewayProcess(runningProcess, 5000)).then(() => {
          dispatch(updateProjectMasterformatProgressCompleted(
            associationIds,
            now,
            runningProcess.id));
          return dispatch(hasFloor ?
                          loadFloorMasterformatProgress(associationIds) :
                          loadProjectMasterformatProgress(associationIds.projectId));
        });
      });
  };
}

export default updateProgressReport;
