import { Reducer } from "redux";
import { isLocationAction, NOT_FOUND } from "redux-first-router";
import { ApiIntegrationCredentialsType } from "avvir";

import PageNames, { ACCEPT_INVITATION, BIMTRACK_AUTHENTICATED, CONFIRM_ACCOUNT, INTEGRATION, INTEGRATIONS, LOGIN, ORGANIZATION, ORGANIZATION_LIST, PHOTO, PHOTOS, PROCORE_AUTHENTICATED, PROJECT, PROJECT_PORTFOLIO, PROJECT_REPORTS, PROJECT_SETTINGS, PROJECT_SUMMARY, PROJECT_TEAM, PROJECT_UPLOADS, PROJECT_VIEWER, RESET_PASSWORD, RESET_PASSWORD_CONFIRMATION_PAGE, RESET_PASSWORD_EMAIL_FORM, SEARCH_SET_CREATOR, SUPERADMIN_ORGANIZATION_USERS, SUPERADMIN_SCANS_TABLE, WORK_BREAKDOWN_STRUCTURE } from "../../models/domain/enums/page_names";
import PanelType from "../../models/domain/enums/panel_type";
import RoutingEvent, { RoutingEventType, TO_ROOT_PAGE } from "../routing_events";
import { PROJECT_NOT_FOUND } from "../../events/notifications/failures/project_not_found";
import { TO_ACCEPT_INVITATION_PAGE } from "../../events/routing/to_accept_invitation_page";
import { TO_CONFIRM_ACCOUNT_PAGE } from "../../events/routing/to_confirm_account_page";
import { TO_CUSTOM_REPORT } from "../../events/routing/to_custom_report";
import { TO_CUSTOM_REPORTS } from "../../events/routing/to_custom_reports";
import { TO_HX_AUTH_LOGIN_PAGE } from "../../events/routing/to_hx_auth_login_page";
import { TO_INSPECT_REPORT } from "../../events/routing/to_inspect_report";
import { TO_INSPECT_REPORTS } from "../../events/routing/to_inspect_reports";
import { TO_INTEGRATION_PAGE } from "../../events/routing/to_integration_page";
import { TO_LOGIN_PAGE } from "../../events/routing/to_login_page";
import { TO_METRICS_PAGE } from "../../events/routing/to_metrics_page";
import { TO_PASSWORD_RESET_CONFIRMATION_PAGE } from "../../events/routing/to_password_reset_confirmation_page";
import { TO_PASSWORD_RESET_EMAIL_FORM } from "../../events/routing/to_password_reset_email_form";
import { TO_PASSWORD_RESET_PAGE } from "../../events/routing/to_password_reset_page";
import { TO_PHOTOS } from "../../events/routing/to_photos_page";
import { TO_PHOTOS_FLOOR } from "../../events/routing/to_photos_floor";
import { TO_PROGRESS_4D_REPORT } from "../../events/routing/to_progress_4d_report";
import { TO_PROGRESS_4D_REPORT_FOR_AREA } from "../../events/routing/to_progress_4d_report_for_area";
import { TO_PROJECT } from "../../events/routing/to_project";
import { TO_PROJECT_INTEGRATIONS_PAGE } from "../../events/routing/to_project_integrations_page";
import { TO_PROJECT_PORTFOLIO_PAGE } from "../../events/routing/to_project_portfolio_page";
import { TO_PROJECT_SETTINGS_PAGE } from "../../events/routing/to_project_settings_page";
import { TO_PROJECT_SUMMARY_PAGE } from "../../events/routing/to_project_summary_page";
import { TO_PROJECT_TEAM_PAGE } from "../../events/routing/to_project_team_page";
import { TO_PROJECT_UPLOADS_PAGE } from "../../events/routing/to_project_uploads_page";
import { TO_SEARCH_SET_CREATOR_PAGE } from "../../events/routing/to_search_set_creator_page";
import { TO_SUMMARY_PROGRESS_4D_REPORT } from "../../events/routing/to_summary_progress_4d_report";
import { TO_SUPERADMIN_FLOOR } from "../../events/routing/superadmin/to_superadmin_floor";
import { TO_SUPERADMIN_NEW_ORGANIZATION } from "../../events/routing/superadmin/to_superadmin_new_organization";
import { TO_SUPERADMIN_NEW_PROJECT } from "../../events/routing/superadmin/to_superadmin_new_project";
import { TO_SUPERADMIN_ORGANIZATION } from "../../events/routing/superadmin/to_superadmin_organization";
import { TO_SUPERADMIN_ORGANIZATION_USERS } from "../../events/routing/superadmin/to_superadmin_organization_users";
import { TO_SUPERADMIN_ORGANIZATIONS } from "../../events/routing/superadmin/to_superadmin_organizations";
import { TO_SUPERADMIN_PROJECT } from "../../events/routing/superadmin/to_superadmin_project";
import { TO_SUPERADMIN_SCAN_DATASET } from "../../events/routing/superadmin/to_superadmin_scan_datasets";
import { TO_SUPERADMIN_SCANS_TABLE } from "../../events/routing/superadmin/to_superadmin_scans_table";
import { TO_SUPERADMIN_USER } from "../../events/routing/superadmin/to_superadmin_user";
import { TO_VIEWER_FLOOR } from "../../events/routing/to_viewer_floor";
import { TO_VIEWER_SCAN_DATASET } from "../../events/routing/to_viewer_scan_dataset";
import { TO_WBS_WITH_FLOOR } from "../../events/routing/to_wbs_with_floor";
import { TO_WBS_WITH_PROJECT } from "../../events/routing/to_wbs_with_project";
import { TO_WBS_WITH_WBS_CODE } from "../../events/routing/to_wbs_with_wbs_code";
import { TO_PHOTO_PAGE } from "../../events/routing/to_photo_page";

export interface LocationMetadataStore {
  organizationId?: string;
  projectId?: string;
  projectReportId?: string;
  floorId?: string;
  scanDatasetId?: string;
  pageTitle?: string;
  pageName: PageNames;
  isClientUserPage?: boolean;
  isSuperadmin?: boolean;
  integrationCredentialsType?: ApiIntegrationCredentialsType;
  isNew?: boolean;
  pageNotFound?: boolean;
  token?: string;
  email?: string;
  procoreAccessToken?: string;
  wbsCode?: string;
  queryParams?: {
    [key: string]: string
  };
  inspectReportId?: number
  focusedPanel?: PanelType
}

function toProjectReportsQueryParams(event: {payload: {embedded?: boolean}}) {
  const queryParams = {};
  if (!!event.payload.embedded) {
    queryParams["embed"] = "t";
  }

  return queryParams;
}

function typeToApiIntegrationType(type: string) {
  switch (type) {
    case "drone-deploy": {
      return ApiIntegrationCredentialsType.DRONE_DEPLOY
    }
    case "multivista": {
      return ApiIntegrationCredentialsType.MULTIVISTA
    }
    case "struction-site": {
      return ApiIntegrationCredentialsType.STRUCTION_SITE
    }
  }
}

function updateLocationMetadata(metadata: LocationMetadataStore, event: RoutingEvent) {
  const queryParams = event.meta?.location?.current?.query;
  if(event.payload?.projectId) {
    window.localStorage.setItem("lastProjectId", JSON.stringify(event.payload.projectId));
  }
  return {
    focusedPanel: null,
    ...metadata,
    ...(queryParams && { queryParams })
  };
}

const reduceLocationMetadata: Reducer<LocationMetadataStore, RoutingEvent> = (state: LocationMetadataStore = { pageName: PageNames.NOT_FOUND }, event) => {
  if (isLocationAction(event)) {
    if (event.type.endsWith("_deprecated")) {
      // @ts-ignore
      event.type = event.type.substr(0, event.type.length - "_deprecated".length) as RoutingEventType;
    }
  }

  switch (event.type) {
    case PROJECT_NOT_FOUND:
    case NOT_FOUND: {
      return updateLocationMetadata({
        pageNotFound: true,
        pageName: PageNames.NOT_FOUND,
        pageTitle: "Not Found",
      }, event);
    }
    case TO_LOGIN_PAGE:
    case TO_ROOT_PAGE: {
      return updateLocationMetadata({
        pageName: LOGIN,
        pageTitle: "Login",
      }, event);
    }
    case TO_HX_AUTH_LOGIN_PAGE: {
      return updateLocationMetadata({
        pageName: PageNames.HX_AUTH_LOGIN,
        pageTitle: "SSO Sign In"
      }, event);
    }
    case TO_SUPERADMIN_NEW_ORGANIZATION: {
      return updateLocationMetadata({
        isSuperadmin: true,
        isNew: true,
        pageName: ORGANIZATION,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_SUPERADMIN_ORGANIZATION: {
      return updateLocationMetadata({
        isSuperadmin: true,
        organizationId: event.payload.organizationId,
        pageName: ORGANIZATION,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_SUPERADMIN_SCANS_TABLE: {
      return updateLocationMetadata({
        isSuperadmin: true,
        pageName: SUPERADMIN_SCANS_TABLE,
        pageTitle: "Superadmin Scandatasets Stats",
      }, event);
    }
    case TO_SUPERADMIN_NEW_PROJECT: {
      return updateLocationMetadata({
        isSuperadmin: true,
        organizationId: event.payload.organizationId,
        isNew: true,
        pageName: PROJECT,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_SUPERADMIN_PROJECT: {
      return updateLocationMetadata({
        isSuperadmin: true,
        organizationId: event.payload.organizationId,
        projectId: event.payload.projectId,
        pageName: PROJECT,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_PROJECT: {
      return updateLocationMetadata({
        isClientUserPage: true,
        pageName: PROJECT_VIEWER,
        projectId: event.payload.projectId,
      }, event);
    }
    case TO_VIEWER_FLOOR: {
      return updateLocationMetadata({
        isClientUserPage: true,
        pageName: PROJECT_VIEWER,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
      }, event);
    }
    case TO_VIEWER_SCAN_DATASET: {
      return updateLocationMetadata({
        isClientUserPage: true,
        pageName: PROJECT_VIEWER,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
        scanDatasetId: event.payload.scanDatasetId,
      }, event);
    }
    case TO_SUMMARY_PROGRESS_4D_REPORT:{
      return updateLocationMetadata({
        isClientUserPage: true,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
        pageName: event.payload.pageName || PROJECT_REPORTS,
        queryParams: toProjectReportsQueryParams(event),
        focusedPanel: PanelType.PROGRESS_4D_REPORT_PANEL,
        inspectReportId: state.inspectReportId
      }, event);
    }
    case TO_PROGRESS_4D_REPORT: {
      return updateLocationMetadata({
        isClientUserPage: true,
        projectId: event.payload.projectId,
        pageName: event.payload.pageName || PROJECT_REPORTS,
        queryParams: toProjectReportsQueryParams(event),
        focusedPanel: PanelType.PROGRESS_4D_REPORT_PANEL,
        inspectReportId: state.inspectReportId
      }, event);
    }
    case TO_PROGRESS_4D_REPORT_FOR_AREA: {
      return updateLocationMetadata({
        isClientUserPage: true,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
        pageName: event.payload.pageName || PROJECT_REPORTS,
        queryParams: toProjectReportsQueryParams(event),
        focusedPanel: PanelType.PROGRESS_4D_REPORT_PANEL,
        inspectReportId: state.inspectReportId
      }, event);
    }
    case TO_CUSTOM_REPORT: {
      return updateLocationMetadata({
        isClientUserPage: true,
        projectId: event.payload.projectId,
        projectReportId: "" + event.payload.projectReportId,
        pageName: event.payload.pageName || PROJECT_REPORTS,
        queryParams: toProjectReportsQueryParams(event),
        focusedPanel: PanelType.CUSTOM_REPORTS_PANEL,
        inspectReportId: state.inspectReportId
      }, event);
    }
    case TO_CUSTOM_REPORTS: {
      return updateLocationMetadata({
        isClientUserPage: true,
        projectId: event.payload.projectId,
        pageName: event.payload.pageName || PROJECT_REPORTS,
        queryParams: toProjectReportsQueryParams(event),
        focusedPanel: PanelType.CUSTOM_REPORTS_PANEL,
        inspectReportId: state.inspectReportId
      }, event);
    }
    case TO_PROJECT_UPLOADS_PAGE: {
      return updateLocationMetadata({
        isClientUserPage: true,
        projectId: event.payload.projectId,
        pageName: PROJECT_UPLOADS,
      }, event);
    }
    case TO_SUPERADMIN_ORGANIZATIONS: {
      return updateLocationMetadata({
        isSuperadmin: true,
        pageName: ORGANIZATION_LIST,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_SUPERADMIN_ORGANIZATION_USERS: {
      return updateLocationMetadata({
        isSuperadmin: true,
        pageName: SUPERADMIN_ORGANIZATION_USERS,
        organizationId: event.payload.organizationId,
        pageTitle: "Organization Users",
      }, event);
    }
    case TO_SUPERADMIN_FLOOR: {
      return updateLocationMetadata({
        isSuperadmin: true,
        organizationId: event.payload.organizationId,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
        pageName: PROJECT,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_SUPERADMIN_SCAN_DATASET: {
      return updateLocationMetadata({
        isSuperadmin: true,
        organizationId: event.payload.organizationId,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
        scanDatasetId: event.payload.scanDatasetId,
        pageName: PROJECT,
        pageTitle: "Superadmin Portal",
      }, event);
    }
    case TO_PHOTOS: {
      return updateLocationMetadata({
        isClientUserPage: true,
        pageName: PHOTOS,
        projectId: event.payload.projectId,
      }, event);
    }
    case TO_SEARCH_SET_CREATOR_PAGE: {
      return updateLocationMetadata({
        isSuperadmin: true,
        pageName: SEARCH_SET_CREATOR,
      }, event);
    }
    case TO_PHOTOS_FLOOR: {
      return updateLocationMetadata({
        isClientUserPage: true,
        pageName: PHOTOS,
        projectId: event.payload.projectId,
        floorId: event.payload.floorId,
      }, event);
    }
    case TO_ACCEPT_INVITATION_PAGE: {
      return updateLocationMetadata({
        pageName: ACCEPT_INVITATION,
        email: event.payload.query?.email,
        token: event.payload.query?.token,
      }, event);
    }
    case TO_PROJECT_TEAM_PAGE: {
      return updateLocationMetadata({
        isClientUserPage: false,
        isSuperadmin: true,
        pageName: PROJECT_TEAM,
        projectId: event.payload.projectId,
      }, event);
    }
    case TO_PROJECT_SETTINGS_PAGE: {
      return updateLocationMetadata({
        isClientUserPage: true,
        pageName: PROJECT_SETTINGS,
        projectId: event.payload.projectId,
      }, event);
    }
    case "to_procore_authenticated_page": {
      return updateLocationMetadata({
        pageName: PROCORE_AUTHENTICATED,
      }, event);
    }
    case "to_bimtrack_authenticated_page": {
      return updateLocationMetadata({
        pageName: BIMTRACK_AUTHENTICATED,
      }, event);
    }
    case TO_CONFIRM_ACCOUNT_PAGE: {
      return updateLocationMetadata({
        pageName: CONFIRM_ACCOUNT,
      }, event);
    }
    case TO_WBS_WITH_FLOOR: {
      return updateLocationMetadata({
        pageName: WORK_BREAKDOWN_STRUCTURE,
        projectId: event.payload.projectId,
        isClientUserPage: true,
        floorId: event.payload.floorId,
      }, event);
    }
    case TO_WBS_WITH_WBS_CODE: {
      return updateLocationMetadata({
        pageName: WORK_BREAKDOWN_STRUCTURE,
        projectId: event.payload.projectId,
        isClientUserPage: true,
        wbsCode: event.payload.wbsCode,
      }, event);
    }
    case TO_WBS_WITH_PROJECT: {
      return updateLocationMetadata({
        pageName: WORK_BREAKDOWN_STRUCTURE,
        projectId: event.payload.projectId,
        isClientUserPage: true,
      }, event);
    }
    case TO_PROJECT_INTEGRATIONS_PAGE: {
      return updateLocationMetadata({
        pageName: INTEGRATIONS,
        projectId: event.payload.projectId,
        isClientUserPage: true,
      }, event);
    }
    case TO_INTEGRATION_PAGE: {
      return updateLocationMetadata({
        pageName: INTEGRATION,
        projectId: event.payload.projectId,
        integrationCredentialsType: typeToApiIntegrationType(event.payload.type),
        isClientUserPage: true,
      }, event);
    }
    //@ts-ignore
    case TO_PHOTO_PAGE: {
      // @ts-ignore
      return updateLocationMetadata({
        pageName: PHOTO,
        //@ts-ignore
        projectId: event.payload.projectId,
        //@ts-ignore
        pageTitle: event.payload.category,
        //@ts-ignore
        integrationCredentialsType: typeToApiIntegrationType(event.payload.type),
        isClientUserPage: true,
      }, event);
    }
    case TO_PROJECT_SUMMARY_PAGE: {
      return updateLocationMetadata({
        pageName: PROJECT_SUMMARY,
        projectId: event.payload.projectId,
        isClientUserPage: true,
        inspectReportId: state.inspectReportId
      }, event);
    }
    case TO_PROJECT_PORTFOLIO_PAGE: {
      return updateLocationMetadata({
        pageName: PROJECT_PORTFOLIO,
        isClientUserPage: true,
      }, event);
    }
    case TO_PASSWORD_RESET_EMAIL_FORM: {
      return updateLocationMetadata({
        pageName: RESET_PASSWORD_EMAIL_FORM,
        pageTitle: "Forgot your password?",
      }, event);
    }
    case TO_PASSWORD_RESET_PAGE: {
      return updateLocationMetadata({
        pageName: RESET_PASSWORD,
        pageTitle: "Password Reset",
      }, event);
    }
    case TO_PASSWORD_RESET_CONFIRMATION_PAGE: {
      return updateLocationMetadata({
        pageName: RESET_PASSWORD_CONFIRMATION_PAGE,
        pageTitle: "Password Reset Confirmation",
      }, event);
    }
    case TO_SUPERADMIN_USER: {
      return updateLocationMetadata({
        pageName: PageNames.SUPERADMIN_USER,
        pageTitle: "User: " + decodeURIComponent(event.payload.email),
        isSuperadmin: true,
        email: event.payload.email,
      }, event);
    }
    case TO_INSPECT_REPORT: {
      return updateLocationMetadata({
        pageName: PROJECT_SUMMARY,
        projectId: event.payload.projectId,
        isClientUserPage: true,
        inspectReportId: parseFloat(event.payload.inspectReportId),
        focusedPanel: PanelType.INSPECT_REPORTS
      }, event)
    }
    case TO_INSPECT_REPORTS: {
      return updateLocationMetadata({
        pageName: PROJECT_SUMMARY,
        projectId: event.payload.projectId,
        isClientUserPage: true,
        focusedPanel: PanelType.INSPECT_REPORTS,
      }, event)
    }
    case TO_METRICS_PAGE: {
      return updateLocationMetadata({
        pageName: PROJECT_SUMMARY,
        projectId: event.payload.projectId,
        isClientUserPage: true,
        focusedPanel: PanelType.PROJECT_METRICS_PANEL
      }, event)
    }
    default: {
      return state;
    }
  }
};

export default reduceLocationMetadata;