import { immerable } from "immer";
import { ApiCloudFile, ApiProjectArea, ApiProjectWorkPackageCost } from "avvir";

import ProjectArea from "./project_area";
import { QualityMetrics } from "../../../events/loaded/metrics/quality_metrics_loaded";
import addReadOnlyPropertiesToModel from "../../../services/utilities/mixins/add_read_only_properties_to_model";
import CloudFile from "../cloud_file";

import type { ModifyPartial } from "type_aliases";

export type ProjectSummaryArgs = ModifyPartial<ProjectSummary, {
  model: CloudFile | ApiCloudFile
}>

export default class ProjectSummary {
  constructor({firebaseId, model, projectAreaIds, selectedModelElementId, metrics}: ProjectSummaryArgs = {}) {
    addReadOnlyPropertiesToModel(this, { firebaseId });
    this.projectAreaIds = projectAreaIds || [];
    this.selectedModelElementId = selectedModelElementId || null;

    if (model) {
      this.model = new CloudFile(model);
    }
    if (metrics) {
      this.metrics = metrics;
    }
  }

  firebaseId: string;
  model?: CloudFile;
  projectAreaIds?: number[];
  selectedModelElementId?: number;
  highlightedModelElementId?: number;
  metrics: {
    quality: QualityMetrics,
    cost: CostMetrics
  } = {
    quality: {
      byArea: {},
      byTrade: {}
    },
    cost: {
      projectCapturedTotal: 0,
      projectEstimatedTotal: 0,
      projectWorkPackageCosts: []
    }
  };

  static readonly [immerable] = true;
}

export class CostMetrics {
  constructor({ projectCapturedTotal, projectEstimatedTotal, projectWorkPackageCosts }) {
    this.projectCapturedTotal = projectCapturedTotal;
    this.projectEstimatedTotal = projectEstimatedTotal;
    this.projectWorkPackageCosts = projectWorkPackageCosts;
  }

  projectCapturedTotal: number;
  projectEstimatedTotal: number;
  projectWorkPackageCosts: ApiProjectWorkPackageCost[];
}

function groupProjectAreasByModelElementId(projectAreas: ApiProjectArea[]): { [k: string]: ProjectArea } {
  let projectAreasByModelElementId = {};
  projectAreas?.forEach((area) => {
    if (area.modelElementId) {
      projectAreasByModelElementId[area.modelElementId] = new ProjectArea(area);
    }
  });
  return projectAreasByModelElementId;
}
