import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminUserEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_USER, {email: string}>

const toSuperadminUser = (email: string): ToSuperadminUserEvent => ({
  type: TO_SUPERADMIN_USER,
  payload: { email }
});

export const TO_SUPERADMIN_USER = "to_superadmin_user";

export default toSuperadminUser;
