import React, { FunctionComponent, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MenuItem } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import "../../../css/nav_bar/nav_bar_panels_menu.css";
import NavBarDropdown from "./nav_bar_dropdown";
import usePopover from "../../services/component_helpers/component_effects/use_popover";
import PdfTypes from "../../services/procore_api_services/pdf_types";
import getExportStarted from "../../services/getters/get_export_started";
import getAllScanDatasetScanDates from "../../services/getters/scan_dataset_getters/get_all_scan_dataset_scan_dates";
import exportIfc from "../../actions/viewer_page/export_ifc";
import downloadDeviatedTsv from "../../actions/viewer_page/download_deviated_tsv";
import exportProgressReport from "../../actions/viewer_page/export_progress_report";
import getProcoreAccessToken from "../../actions/viewer_page/procore/get_procore_access_token";
import useSwitch from "@react-hook/switch";
import ExportDeviationsIfcDialog, { DeviationsIfcType } from "../dialogs/export_deviations_ifc_dialog";
import ExportProgressReportPdfDialog from "../dialogs/export_progress_report_pdf_dialog";

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const NavBarExportDropdownMenu: FunctionComponent<Props> = (props) => {
  const {
    exportStarted,
    exportIfc,
    downloadDeviatedTsv,
    allScanDates,
    exportProgressReport,
    getProcoreAccessToken,
  } = props;

  const [openState, anchorEl, handleOpen, onClose] = usePopover();
  const [exportDeviationsDialogOpen, toggleExportDeviationsDialog] = useSwitch(false);
  const [exportProgressReportDialogOpen, toggleExportProgressReportDialogOpen] = useSwitch(false);
  const { pushDeviationsToProcore } = useFlags();
  const [progressReportDateOption, setProgressReportDateOption] = useState<"all_dates" | "current_date" | "date_range">("all_dates");
  return <div>
    <NavBarDropdown
      className="NavBarPanelsMenu-button btn-export"
      label="Exports"
      popoverProps={{
        open: openState,
        anchorEl,
        handleOpen,
        onClose
      }}
      slotProps={{
        menu: {
          className: "NavBarPanelsMenu"
        }
      }}
    >

      <MenuItem className="NavBarKebabMenu-asBuilt NavBarKebabMenu-menuItem" disabled={exportStarted} onClick={() => {exportIfc("as_built")}}>
        As-built (.ifc)
      </MenuItem>
      <MenuItem className="NavBarKebabMenu-deviations NavBarKebabMenu-menuItem" disabled={exportStarted} onClick={toggleExportDeviationsDialog.on}>
        Deviations (.ifc)
      </MenuItem>
      <MenuItem className="NavBarKebabMenu-notBuilt NavBarKebabMenu-menuItem" disabled={exportStarted} onClick={() => exportIfc("not_built")}>
        Not Built (.ifc)
      </MenuItem>
      <MenuItem className="NavBarKebabMenu-elementsTsv NavBarKebabMenu-menuItem" disabled={exportStarted} onClick={downloadDeviatedTsv}>
        Elements (.tsv)
      </MenuItem>
      <MenuItem className="NavBarKebabMenu-levelProgress NavBarKebabMenu-menuItem" disabled={exportStarted} onClick={toggleExportProgressReportDialogOpen.on}>
        Level Progress (.pdf)
      </MenuItem>
      {pushDeviationsToProcore ?
       <MenuItem className="NavBarKebabMenu-pushDeviationsToProcore NavBarPanelsMenu-menuItem" onClick={() => getProcoreAccessToken(PdfTypes.DEVIATIONS)}>
         Push Raw Data to Procore
       </MenuItem> :
       null}
      <MenuItem className="NavBarKebabMenu-pushProgressToProcore NavBarPanelsMenu-menuItem" onClick={() => getProcoreAccessToken(PdfTypes.PROGRESS)}>
        Push Progress Report to Procore
      </MenuItem>

    </NavBarDropdown>

    <ExportDeviationsIfcDialog
      open={exportDeviationsDialogOpen}
      onClose={toggleExportDeviationsDialog.off}
      onClickOk={(deviationsIfcType: DeviationsIfcType) => {
        exportIfc(deviationsIfcType);
        toggleExportDeviationsDialog.off();
      }}
    />


    <ExportProgressReportPdfDialog
      open={exportProgressReportDialogOpen}
      allScanDates={allScanDates}
      onClose={toggleExportProgressReportDialogOpen.off}
      onClickOk={(startDate, endDate) => {
        exportProgressReport(startDate, endDate, progressReportDateOption);
        toggleExportProgressReportDialogOpen.off();
      }}
      progressReportDateOption={progressReportDateOption}
      setProgressReportDateOption={setProgressReportDateOption} />
  </div>


};

const mapStateToProps = (state, props) => ({
  exportStarted: getExportStarted(state, props),
  allScanDates: getAllScanDatasetScanDates(state, props),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  exportIfc,
  downloadDeviatedTsv,
  exportProgressReport,
  getProcoreAccessToken,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NavBarExportDropdownMenu);
