import Avvir, { ApiMasterformatProgress, ResponseError } from "avvir";

import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import MasterformatProgressConverter from "../../services/converters/masterformat_progress_converter";
import projectMasterformatProgressLoaded, { ProjectMasterformatProgressLoadedEvent } from "../../events/loaded/projects/project_masterformat_progress_loaded";
import projectNotFound, { ProjectNotFoundEvent } from "../../events/notifications/failures/project_not_found";


const loadProjectMasterformatProgress = (projectId: string): AvvirThunk<Promise<ApiMasterformatProgress[]>, ProjectMasterformatProgressLoadedEvent | ProjectNotFoundEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;

    return Promise.all([
        Avvir.api.projects.getScannedProjectMasterformatProgress({ projectId }, user)
          .then(MasterformatProgressConverter.convertScanDatesFromInstant),
        Avvir.api.projects.getScheduledProjectMasterformatProgress({ projectId }, "baseline", user)
          .then(MasterformatProgressConverter.convertScanDatesFromInstant),
        Avvir.api.projects.getScheduledProjectMasterformatProgress({ projectId }, "current", user)
          .then(MasterformatProgressConverter.convertScanDatesFromInstant)
      ])
      .then(([scanned, baseline, current]) => {
        dispatch(projectMasterformatProgressLoaded(projectId, scanned, baseline, current));
        return scanned;
      }).catch((error) => {
        if (error instanceof ResponseError && error.status === 404 && error.message.toLowerCase().includes("project does not exist")) {
          dispatch(projectNotFound());
        } else {
          throw error;
        }
      }) as Promise<ApiMasterformatProgress[]>;
  };
};

export default makeEventfulAction("loadProjectMasterformatProgress", loadProjectMasterformatProgress);
