import { Reducer } from "redux";
import { ApiUser, ApiUserPermission, FirebaseUser, GatewayUser, HxAuthUser, User, UserAuthType, UserRole } from "avvir";

import { decodeAvvirGatewayToken, decodeJwtToPayload } from "../firebase_services/firebase_tokens";
import { FIREBASE_USER_SIGNED_IN, FirebaseUserSignedInEvent } from "../../events/auth_events/firebase_user_signed_in";
import { GATEWAY_USER_SIGNED_IN, GatewayUserSignedInEvent } from "../../events/auth_events/gateway_user_signed_in";
import { GCP_BEARER_TOKEN_LOADED, GcpBearerTokenLoadedEvent } from "../../events/loaded/gcp_bearer_token_loaded";
import { USER_ACCOUNT_LOADED, UserAccountLoadedEvent } from "../../events/auth_events/user_account_loaded";
import { USER_ACCOUNT_UPDATED, UserAccountUpdatedEvent } from "../../events/auth_events/user_account_updated";
import { USER_SIGNED_OUT, UserSignedOutEvent } from "../../events/auth_events/user_signed_out";
import { USER_PERMISSIONS_LOADED, UserPermissionsLoadedEvent } from "../../events/auth_events/user_permissions_loaded";
import { HXAUTH_USER_SIGNED_IN, HxAuthUserSignedInEvent } from "../../events/auth_events/hxauth_user_signed_in";

export type UserAccount = {
  name?: string,
  email: string,
  userOrganization?: string
  role: UserRole,
  projectId?: string,
  userId?: number,
  expireTime?: number
}

export type SessionStore = {
  bimtrackAuthCode?: string
  userRole: string
  procoreAccessToken?: string
  account: UserAccount
  gcpBearerTokensByFirebaseOrganizationId?: {
    [firebaseId: string]: {
      organizationId: string
      accessToken: string
      expireTime: Date
    }
  },
  permissions: ApiUserPermission[]
}

export type UserSessionStoreEvent =
  | GcpBearerTokenLoadedEvent
  | FirebaseUserSignedInEvent
  | GatewayUserSignedInEvent
  | UserSignedOutEvent
  | { type: "to_procore_authenticated_page" }
  | UserAccountUpdatedEvent
  | UserAccountLoadedEvent
  | UserPermissionsLoadedEvent
  | HxAuthUserSignedInEvent;

export type UserSessionStore = GatewayUser & SessionStore | FirebaseUser & SessionStore | HxAuthUser & SessionStore | null;

const extractToken = (hash: string) => {
  const match = hash.match(/access_token=([\w.-]+)/);
  return match?.[1];
};

// reducers inherently use complex switch statements
// noinspection OverlyComplexFunctionJS
const reduceUserSession: Reducer<UserSessionStore, UserSessionStoreEvent> = (user: UserSessionStore = null, event) => {
  switch (event.type) {
    case FIREBASE_USER_SIGNED_IN: {
      let account = user?.account ? user.account : new ApiUser();
      return {
        ...user,
        account: {
          role: event.payload.role,
          email: event.payload.email,
          name: event.payload.displayName,
          userOrganization: account?.userOrganization,
          expireTime: user?.account.expireTime
        },
        firebaseUser: {
          displayName: event.payload.displayName,
          uid: event.payload.uid,
          role: event.payload.role,
          idToken: event.payload.idToken,
        },
        authType: user?.authType as typeof UserAuthType.FIREBASE || UserAuthType.FIREBASE
      };
    }
    case USER_SIGNED_OUT: {
      return null;
    }
    case GATEWAY_USER_SIGNED_IN: {
      let account = user?.account ? user.account : new ApiUser();
      const decoded = decodeAvvirGatewayToken(event.payload.jwt);
      return {
        ...user,
        account: {
          role: decoded.role,
          email: decoded.sub,
          name: account?.name,
          userOrganization: account?.userOrganization,
          projectId: event.payload.projectId,
          userId: account?.userId,
          expireTime: decoded.exp
        },
        authType: UserAuthType.GATEWAY_JWT,
        gatewayUser: {
          idToken: event.payload.jwt,
          role: decoded.role
        }
      };
    }
    case HXAUTH_USER_SIGNED_IN: {
      const decoded = decodeJwtToPayload(event.payload.accessToken) as { exp: number };
      return {
        ...user,
        account: {
          ...user?.account
        },
        authType: UserAuthType.HXAUTH_ACCESS_TOKEN,
        hxAuthUser: {
          accessToken: event.payload.accessToken,
          refreshToken: event.payload.refreshToken,
          expireTime: decoded.exp
        }
      };
    }
    case "to_procore_authenticated_page": {
      return {
        ...user,
        procoreAccessToken: extractToken(window.location.hash),
      };
    }
    case GCP_BEARER_TOKEN_LOADED: {
      return {
        ...user,
        gcpBearerTokensByFirebaseOrganizationId: {
          ...user?.gcpBearerTokensByFirebaseOrganizationId,
          [event.payload.organizationId]: {
            ...event.payload,
            expireTime: new Date(event.payload.expireTime),
          }
        }
      };
    }
    case USER_ACCOUNT_UPDATED: {
      return {
        ...user,
        account: event.payload.user
      };
    }
    case USER_ACCOUNT_LOADED: {
      return {
        ...user,
        account: {
          ...user?.account,
          ...event.payload.user
        }
      };
    }
    case USER_PERMISSIONS_LOADED: {
      return {
        ...user,
        permissions: event.payload.permissions
      };
    }
    default:
      return user;
  }
};

export default reduceUserSession;
