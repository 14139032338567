import { AvvirEvent } from "type_aliases";

export type OnlyDeviationsFromCurrentScanShownEvent = AvvirEvent<typeof ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN, null>

export const onlyDeviationsFromCurrentScanShown = (): OnlyDeviationsFromCurrentScanShownEvent => ({
  type: ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN,
  payload: null
});

export const ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN = "only_show_deviations_from_current_scan";

export type DeviationsFromAllScansShownEvent = AvvirEvent<typeof DEVIATIONS_FROM_ALL_SCANS_SHOWN, null>

export const deviationsFromAllScansShown = (): DeviationsFromAllScansShownEvent => ({
  type: DEVIATIONS_FROM_ALL_SCANS_SHOWN,
  payload: null
});

export const DEVIATIONS_FROM_ALL_SCANS_SHOWN = "deviations_from_all_scans_shown";

