
import { BASIC, FIREBASE, GATEWAY_JWT } from "../../models/domain/enums/user_auth_type";
import {FirebaseUser,BasicUser,GatewayUser, UserRole, User} from "avvir";

/** @deprecated Import these directly from "avvir" instead */
export {GatewayUser, UserRole};
/** @deprecated Import these directly from "avvir" instead */
export type { FirebaseUser, BasicUser, User };

const getAuthorizationHeaders = (user: User) => {
  switch (user?.authType) {
    case GATEWAY_JWT: {
      return {
        Authorization: `Bearer ${user.gatewayUser.idToken}`
      };
    }
    case BASIC: {
      return {
        Authorization: `Basic ${Buffer.from(`${user.username}:${user.password}`).toString("base64")}`
      };
    }
    case FIREBASE: {
      return {
        firebaseIdToken: user.firebaseUser.idToken
      };
    }
    default: {
      console.warn("no authentication");
      return null;
    }
  }
};

/** @deprecated Use equivalent from "avvir" instead */
export default getAuthorizationHeaders;
